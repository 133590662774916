import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyGetDailyDataQuery } from 'services/api/products/flux';
import {
  selectFluxAutomaticControlPanelAutomaticStudyScenarios,
  selectFluxAutomaticControlPanelPastSimulations,
  selectFluxAutomaticControlPanelSpatialSubdivision,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { DailyData, buildSubdivisionParam } from 'helper/flux/automatic';
import { ResponseError } from 'services/api/base';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import useAcomphMLT from './useAcomphMLT';

export default function useDailyData() {
  const [data, setData] = useState<DailyData>({});
  const [isFetching, setIsFetching] = useState(false);
  const [trigger, { error }] = useLazyGetDailyDataQuery();
  const spatialSubdivision = useSelector(selectFluxAutomaticControlPanelSpatialSubdivision);
  const models = useSelector(selectFluxAutomaticControlPanelAutomaticStudyScenarios);
  const { acomph, mlt } = useAcomphMLT();
  const pastSimulations = useSelector(selectFluxAutomaticControlPanelPastSimulations);

  useHandleRequestErrors(error as ResponseError);

  useEffect(() => {
    if (spatialSubdivision !== undefined && models.length > 0) {
      const spatialSubdivisionParam = buildSubdivisionParam(spatialSubdivision);

      (async () => {
        setIsFetching(true);
        const res = await trigger({
          data_acomph: 'latest',
          data_previsao: 'latest',
          versao: 'latest',
          lista_cenarios: models,
          spatialSubdivisionParam,
          incluir_acomph: acomph,
          incluir_mlt: mlt,
          pastSimulations,
        });
        setData(res.data || {});
        setIsFetching(false);
      })();
    } else {
      setData({});
      setIsFetching(false);
    }
  }, [trigger, spatialSubdivision, models, acomph, mlt, pastSimulations]);

  return {
    data,
    isFetching,
  };
}

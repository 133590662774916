import { NavigateFunction } from 'react-router';
import { ResponseError } from '../services/api/base';
import handleAtuthorizationErrors from './tokenError';
import sendErrorToSentry from './sendErrorToSentry';

/* eslint-disable import/prefer-default-export */
export const handleApiError = (
  navigate: NavigateFunction,
  error: ResponseError,
): void => {
  const hasAuthError = handleAtuthorizationErrors(error, navigate);
  if (hasAuthError) return;

  sendErrorToSentry(error);
  navigate(+error.status === 404 ? '/notfound' : '/error');
};

import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { selectUserIsAdmin } from 'redux/reducers/authReducer';
import { useHandleRequestErrors } from 'utils/hooks';
import { ResponseError } from 'services/api/base';
import { useGetVideoDataByIdQuery } from 'services/api/videos';
import {
  Button,
  Spinner,
  Row,
  Col,
} from 'react-bootstrap';
import useBulletinDownload from 'utils/hooks/videos/useBulletinDownload';
import RemoveButton from 'components/Videos/RemoveButton';
import './index.scss';
import EditButton from 'components/Videos/EditButton';
import FormModal from 'components/FormModal';
import {
  VideoData,
  VideoProduct,
  videoProductToPageTitle,
  videoProductToPath,
  videoProductToRoute,
  videoProductToPermission,
} from 'helper/videos';
import VideoForm from '../../../components/Videos/Form';

export default function ShowVideo({
  product,
  viewOnly,
}: {
  product: VideoProduct;
  viewOnly: boolean;
}) {
  const productPath = videoProductToPath[product];
  const productRoute = videoProductToRoute[product];
  const pageTitle = videoProductToPageTitle[product];
  const permissionType = videoProductToPermission[product];

  const userIsAdmin = useSelector(selectUserIsAdmin);
  const { id } = useParams();
  const {
    data,
    isLoading,
    error,
  } = useGetVideoDataByIdQuery({ id: Number(id), productPath, permissionType });
  const videoData: VideoData | undefined = data?.data;
  const {
    onDownload,
    downloadLoading,
  } = useBulletinDownload(Number(id), product);
  const [showVideoForm, setShowVideoForm] = useState(false);
  const videoHasAFile = typeof data?.data?.file_name === 'string';

  useHandleRequestErrors(error as ResponseError);

  return (
    <>
      <h1>{pageTitle}</h1>
      {isLoading && (
        <div className="text-center"><Spinner /></div>
      )}
      {videoData && (
        <>
          <div className="d-flex flex-row justify-content-between">
            <Link
              to={`${productRoute}/categories/${videoData?.categoria}`}
              className="video-back"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              voltar
            </Link>
            { videoHasAFile && (
              <div>
                <Button
                  variant="link"
                  className="bulletin-download"
                  onClick={onDownload}
                  disabled={downloadLoading}
                >
                  Baixar apresentação
                </Button>
                <Button
                  className={`bulletin-download-${downloadLoading ? 'loading' : 'button'}`}
                  onClick={onDownload}
                  disabled={downloadLoading}
                >
                  {downloadLoading && <Spinner size="sm" />}
                  {!downloadLoading && <FontAwesomeIcon icon={faDownload} size="sm" />}
                </Button>
              </div>
            ) }
          </div>
          <div className="meteorology-video-container">
            <iframe
              title={videoData.video_title}
              src={videoData.uri.player_embed_url}
              className="meteorology-video"
              allowFullScreen
            />
          </div>
          <div className="meteorology-video-date">
            {(new Date(videoData.uri.release_time)).toLocaleDateString('pt-BR')}
          </div>
          <Row>
            <Col>
              <div className="meteorology-video-title">
                {videoData.video_title}
              </div>
            </Col>
            {userIsAdmin && !viewOnly && (
              <Col xs={2}>
                <div className="d-flex justify-content-end align-items-baseline me-2 mt-2">
                  <EditButton id={videoData.id} onClick={() => setShowVideoForm(true)} />
                  <RemoveButton
                    id={videoData.id}
                    product={product}
                    categoryId={videoData?.categoria}
                  />
                </div>
                <div>
                  <FormModal
                    show={showVideoForm}
                    title="Editar Vídeo"
                    onHide={() => setShowVideoForm(false)}
                  >
                    <VideoForm id={videoData.id} product={product} />
                  </FormModal>
                </div>
              </Col>
            )}
          </Row>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: videoData.video_description }} />
        </>
      )}
    </>
  );
}

import { useState } from 'react';

export const positivePolarity = 'positive';
export const negativePolarity = 'negative';

export default function usePolaritySelection(area: string) {
  const showPolaritySelector = area !== 'mjo' && area !== 'tropicos';
  const [basePolarity, setPolarity] = useState(positivePolarity);
  const polarity = showPolaritySelector ? basePolarity : undefined;
  const handlePolarityChange = (value: string) => setPolarity(value);

  return {
    showPolaritySelector,
    polarity,
    handlePolarityChange,
  };
}

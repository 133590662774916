import React, { SetStateAction } from 'react';
import { Card } from 'react-bootstrap';
import { selectUserIsAdmin } from 'redux/reducers/authReducer';
import { useSelector } from 'react-redux';
import EditButton from 'components/Shared/EditButton';

export default function DescriptionPanel({
  description,
  setEditing,
}: {
  description: string,
  setEditing: React.Dispatch<SetStateAction<boolean>>,
}) {
  const userIsAdmin = useSelector(selectUserIsAdmin);

  return (
    <Card className="shadow-sm forecast-instruction-card h-100">
      <Card.Header className="d-flex">
        <div className="flex-fill">
          DESCRIÇÃO
        </div>
        {userIsAdmin && (
          <div className="h-100 d-flex align-items-center">
            <EditButton id="edit-description" onClick={() => setEditing(true)} />
          </div>
        )}
      </Card.Header>
      <Card.Body>
        <div className="weather-description">
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
            className="text-justified"
          />
        </div>
      </Card.Body>
    </Card>
  );
}

import { useEffect, useState } from 'react';
import { ResponseError } from 'services/api/base';
import { useLazyGetAreaPredictionQuery } from 'services/api/Meteorology/Weather/Area/Status';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useAreaPredictionImage() {
  const [image, setImage] = useState<string>('');
  const [trigger, {
    data, error, isFetching,
  }] = useLazyGetAreaPredictionQuery();

  useHandleRequestErrors(error as ResponseError);

  useEffect(() => {
    if (data && !isFetching) {
      setImage(data.predictionImageBase64);
    }
  }, [data, isFetching]);

  return {
    predictionImageBase64: image,
    predictionImageLoading: isFetching,
    setImage,
    triggerPrediction: trigger,
  };
}

import { useEffect } from 'react';

export default function useKeyPress(keys: string[], callback: () => void) {
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (keys.find((value) => value === e.key)) callback();
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [keys, callback]);
}

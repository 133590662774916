import { store } from 'redux/store';
import { selectMeteorologyKey } from 'redux/reducers/productsReducer';
import baseApi from 'services/api/base';
import {
  transformGetPredictionResponse,
  transformGetStatusResponse,
} from 'helper/Meteorology/Weather/Area/Status';
import transformGetAaoNaoOptionsResponse from 'helper/Meteorology/Weather/Area/Status/AAONAO';
import { StatusImage } from 'types/Meteorology/Weather/Area/Status/getStatusResponse';
import { PredictionImage } from 'types/Meteorology/Weather/Area/Status/getPredictionResponse';
import { GetAaoNaoOptionParams } from 'types/Meteorology/Weather/Area/Status/AAONAO/getAaoNaoOptionsParams';
import { GetAaoNaoStatusParams } from 'types/Meteorology/Weather/Area/Status/AAONAO/getAaoNaoStatusParams';
import { GetAaoNaoPredictionParams } from 'types/Meteorology/Weather/Area/Status/AAONAO/getAaoNaoPredictionParams';
import { AaoNaoOptions } from 'types/Meteorology/Weather/Area/Status/AAONAO/getAaoNaoOptionsResponse';

export const weatherAreaStatusAaoNaoApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAaoNaoOptions: build.query<AaoNaoOptions, GetAaoNaoOptionParams>({
      transformResponse: transformGetAaoNaoOptionsResponse,
      query: ({ area }) => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/options-aao-nao/?product_key=${productKey}`,
          method: 'POST',
          body: { area },
        };
      },
    }),
    getAaoNaoStatus: build.query<StatusImage, GetAaoNaoStatusParams>({
      transformResponse: transformGetStatusResponse,
      query: ({ area, period }) => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/status-aao-nao/?product_key=${productKey}`,
          method: 'POST',
          body: { area, period },
        };
      },
    }),
    getAaoNaoPrediction: build.query<PredictionImage, GetAaoNaoPredictionParams>({
      transformResponse: transformGetPredictionResponse,
      query: ({ area, model }) => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/prediction-aao-nao/?product_key=${productKey}`,
          method: 'POST',
          body: { area, modelo: model },
        };
      },
    }),
  }),
});

export const {
  useGetAaoNaoOptionsQuery,
  useLazyGetAaoNaoOptionsQuery,
  useGetAaoNaoStatusQuery,
  useLazyGetAaoNaoStatusQuery,
  useGetAaoNaoPredictionQuery,
  useLazyGetAaoNaoPredictionQuery,
} = weatherAreaStatusAaoNaoApi;

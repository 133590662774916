import { store } from 'redux/store';
import { selectMeteorologyKey } from 'redux/reducers/productsReducer';
import baseApi from 'services/api/base';
import { MJOOptions } from 'types/Meteorology/Weather/Area/Status/MJO/getMJOOptionsResponse';
import { GetMJOStatusParams } from 'types/Meteorology/Weather/Area/Status/MJO/getMJOStatusParams';
import { MJOStatus } from 'types/Meteorology/Weather/Area/Status/MJO/getMJOStatusResponse';
import { GetMJOPredictionParams } from 'types/Meteorology/Weather/Area/Status/MJO/getMJOPredictionParams';
import { MJOPrediction } from 'types/Meteorology/Weather/Area/Status/MJO/getMJOPredictionResponse';
import {
  transformGetMJOOptionsResponse,
  transformGetMJOPredictionResponse,
  transformGetMJOStatusResponse,
} from 'helper/Meteorology/Weather/Area/Status/MJO';

export const weatherMJOStatusApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMJOOptions: build.query<MJOOptions, void>({
      transformResponse: transformGetMJOOptionsResponse,
      query: () => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/options-mjo/?product_key=${productKey}`,
          method: 'POST',
          body: {
            area: 'mjo',
          },
        };
      },
    }),
    getMJOStatus: build.query<MJOStatus, GetMJOStatusParams>({
      transformResponse: transformGetMJOStatusResponse,
      query: ({ model }) => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/status-mjo/?product_key=${productKey}`,
          method: 'POST',
          body: {
            area: 'mjo',
            modelo: model,
          },
        };
      },
    }),
    getMJOPrediction: build.query<MJOPrediction, GetMJOPredictionParams>({
      transformResponse: transformGetMJOPredictionResponse,
      query: ({ model, variable }) => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/prediction-mjo/?product_key=${productKey}`,
          method: 'POST',
          body: {
            area: 'mjo',
            modelo: model,
            variavel: variable,
          },
        };
      },
    }),
  }),
});

export const {
  useGetMJOOptionsQuery,
  useGetMJOStatusQuery,
  useLazyGetMJOStatusQuery,
  useGetMJOPredictionQuery,
  useLazyGetMJOPredictionQuery,
} = weatherMJOStatusApi;

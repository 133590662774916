import { TropicoOptions, GetTropicoOptionsResponse } from 'types/Meteorology/Weather/Area/Status/Tropico/getTropicoOptionsResponse';
import { TropicoStatusImages, GetTropicoStatusResponse } from 'types/Meteorology/Weather/Area/Status/Tropico/getTropicoStatusResponse';

export const transformGetTropicoOptionsResponse = (
  response: GetTropicoOptionsResponse,
): TropicoOptions => ({
  models: response.data.modelos,
  waves: response.data.ondas,
});

export const transformGetTropicoStatusResponse = (
  response: GetTropicoStatusResponse,
): TropicoStatusImages => ({
  olrBase64: response.data.olr_base64,
  vpotBase64: response.data.vpot_base64,
});

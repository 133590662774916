import { ResponseError } from 'services/api/base';
import { useGetMJOOptionsQuery } from 'services/api/Meteorology/Weather/Area/Status/MJO';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useMJOOptions() {
  const { data, error, isLoading } = useGetMJOOptionsQuery();

  useHandleRequestErrors(error as ResponseError);

  return { data, isLoading };
}

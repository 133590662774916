import React from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import useConfirmationDialogBox from 'utils/useConfirmationDialogBox';
import SortingArrow from 'components/SortingArrow';
import EmptyListAlert from 'components/EmptyListAlert';
import {
  SortableField as SortableCompanyField,
  SORT_BY_NAME as SORT_BY_COMPANY_NAME,
  SORT_BY_CONTRACT_START,
  SORT_BY_CONTRACT_END,
} from 'services/api/companies';
import {
  SortableField as SortableUserField,
  SORT_BY_NAME as SORT_BY_USER_NAME,
  SORT_BY_EMAIL,
  SORT_BY_COMPANY,
} from 'services/api/users';

import { ListedCompanyContract } from 'helper/companies';
import { ListedUser } from 'helper/users';
import { Sorter } from 'components/SortingArrow/helpers';
import { SortDirection } from 'constants/sorting';
import UserRow from 'components/Admin/Users/UserRow';
import CompanyRow from 'components/Admin/Companies/Table/Row';
import './table.scss';

export enum AdminTableType {
  USERS = 'users',
  CONTRACTS = 'contracts',
}

export default function AdminTable({
  contentType,
  companiesData,
  usersData,
  isFetching,
  sorters,
  sortBy,
  sortDirection,
  listEnded,
  onEditClick,
}: {
  contentType: AdminTableType,
  companiesData: ListedCompanyContract[] | undefined
  usersData: ListedUser[] | undefined
  isFetching: boolean,
  sorters: { [field: string]: Sorter },
  sortBy: SortableUserField | SortableCompanyField | undefined,
  sortDirection: SortDirection | undefined,
  listEnded: boolean,
  onEditClick: ((id: number, contract_id: number) => () => void) | undefined,
}) {
  const { confirmationDialogBox, setConfirmationDialogBox } = useConfirmationDialogBox();

  const firstThreeColumnSettings = [
    {
      title: contentType === AdminTableType.CONTRACTS ? 'RAZÃO SOCIAL' : 'NOME',
      field: contentType === AdminTableType.CONTRACTS ? SORT_BY_COMPANY_NAME : SORT_BY_USER_NAME,
      sort: sorters.nome,
    },
    {
      title: contentType === AdminTableType.CONTRACTS ? 'INÍCIO CONTRATO' : 'USUÁRIO',
      field: contentType === AdminTableType.CONTRACTS ? SORT_BY_CONTRACT_START : SORT_BY_EMAIL,
      sort: contentType === AdminTableType.CONTRACTS ? sorters.contract_start : sorters.username,
    },
    {
      title: contentType === AdminTableType.CONTRACTS ? 'FIM CONTRATO' : 'EMPRESA',
      field: contentType === AdminTableType.CONTRACTS ? SORT_BY_CONTRACT_END : SORT_BY_COMPANY,
      sort: contentType === AdminTableType.CONTRACTS ? sorters.contract_end : sorters.empresa,
    },
  ];

  return (
    <>
      {confirmationDialogBox}
      <div key="CompaniesTable" className="admin-table-main">
        <Row className="fw-bold admin-table-header" key="tableHeader">
          <Col key="tableHeader1" md="2">
            <Stack direction="horizontal">
              {firstThreeColumnSettings[0].title}
              <SortingArrow
                field={firstThreeColumnSettings[0].field}
                sortBy={sortBy}
                sortDirection={sortDirection}
                sort={firstThreeColumnSettings[0].sort}
              />
            </Stack>
          </Col>
          <Col key="tableHeader2" md="2">
            <Stack direction="horizontal">
              {firstThreeColumnSettings[1].title}
              <SortingArrow
                field={firstThreeColumnSettings[1].field}
                sortBy={sortBy}
                sortDirection={sortDirection}
                sort={firstThreeColumnSettings[1].sort}
              />
            </Stack>
          </Col>
          <Col key="tableHeaderFor3" md="2">
            <Stack direction="horizontal">
              {firstThreeColumnSettings[2].title}
              <SortingArrow
                field={firstThreeColumnSettings[2].field}
                sortBy={sortBy}
                sortDirection={sortDirection}
                sort={firstThreeColumnSettings[2].sort}
              />
            </Stack>
          </Col>
          <Col md="3">PRODUTOS</Col>
          <Col md="1" />
          <Col md="2">AÇÕES</Col>
        </Row>
        <EmptyListAlert
          component={contentType === AdminTableType.CONTRACTS ? CompanyRow : UserRow}
          array={contentType === AdminTableType.CONTRACTS ? companiesData?.map((company) => ({
            company,
            key: (company.contrato_id || company.empresa_id),
            setConfirmationDialogBox,
            onEditClick,
          })) : usersData?.map((user) => ({
            user,
            key: user.id,
            setConfirmationDialogBox,
          }))}
          message="Não foram encontrados resultados para os filtros selecionados"
          isFetching={isFetching}
          listEnded={listEnded}
        />
      </div>
    </>
  );
}

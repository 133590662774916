import { useEffect } from 'react';
import { ResponseError } from 'services/api/base';
import { useLazyGetTropicoStatusQuery } from 'services/api/Meteorology/Weather/Area/Status/Tropico';
import { useHandleRequestErrors } from 'utils/hooks';

export default function useTropicoStatus(model: string, wave: string) {
  const [trigger, { data, error, isLoading }] = useLazyGetTropicoStatusQuery();

  useHandleRequestErrors(error as ResponseError);

  useEffect(() => {
    if (model && wave) {
      trigger({ model, wave });
    }
  }, [model, wave, trigger]);

  return {
    olrImageBase64: data?.olrBase64 || '',
    vpotImageBase64: data?.vpotBase64 || '',
    imagesLoading: isLoading,
  };
}

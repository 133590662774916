import { IndicesPrediction } from 'types/Meteorology/Weather/Area/Status/getPlumaResponse';
import downloadFile from 'helper/downloadFile';

export default function useIndexPredictionDownload({ area, data }: {
  area: string,
  data: IndicesPrediction | undefined,
}) {
  const disabled = !data || data.length === 0;
  const onDownloadClick = () => {
    if (!disabled) {
      const rows = ['Model;Date;Index'];
      data.forEach((modelData) => {
        modelData.indices.forEach((index) => {
          rows.push(`${modelData.model};${index.date};${index.value?.toLocaleString('pt-BR') || ''}`);
        });
      });
      const csvData = btoa(rows.join('\n'));
      downloadFile(csvData, `previsao_indice_${area}.csv`);
    }
  };

  return {
    onDownloadClick,
    disabled,
  };
}

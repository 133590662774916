import { useSelector, useDispatch } from 'react-redux';

import {
  selectFluxAutomaticControlPanelClassicAcomphDate,
  selectFluxAutomaticControlPanelClassicForecastDate,
  selectFluxAutomaticControlPanelClassicModel,
  setClassicAcomphDate,
  setClassicForecastDate,
  setClassicModel,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { useGetClassicOptionsQuery } from 'services/api/products/flux';
import { ResponseError } from 'services/api/base';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import { convertAcomphDateLabel, findAcomphDateLabel } from 'helper/flux/automatic';

export default function useClassicOptions() {
  const dispatch = useDispatch();
  const { data, error } = useGetClassicOptionsQuery();

  const acomphDateLabel = useSelector(selectFluxAutomaticControlPanelClassicAcomphDate);
  const forecastDateLabel = useSelector(selectFluxAutomaticControlPanelClassicForecastDate);
  const model = useSelector(selectFluxAutomaticControlPanelClassicModel);

  useHandleRequestErrors(error as ResponseError);

  const acomphDateLabels = data ? Object.keys(data) : [];
  const acomphDates = acomphDateLabels.map(convertAcomphDateLabel) as Date[];
  const acomphDate = convertAcomphDateLabel(acomphDateLabel);

  const forecastDates = data && data[acomphDateLabel]
    ? Object.keys(data[acomphDateLabel])
    : [];

  const baseModels = data && data[acomphDateLabel] && data[acomphDateLabel][forecastDateLabel]
    ? data[acomphDateLabel][forecastDateLabel]
    : [];
  const models = baseModels.map((m) => m.split('.')[0]);

  const setAcomphDate = (date: Date) => {
    const label = findAcomphDateLabel(date, acomphDateLabels);
    dispatch(setClassicAcomphDate(label));
    dispatch(setClassicForecastDate(''));
    dispatch(setClassicModel(''));
  };

  const setForecastDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setClassicForecastDate(e.target.value));
    dispatch(setClassicModel(''));
  };

  const setModel = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setClassicModel(e.target.value));
  };

  return {
    acomphDateLabel,
    acomphDate,
    forecastDateLabel,
    model,
    acomphDates,
    forecastDates,
    models,
    setAcomphDate,
    setForecastDate,
    setModel,
  };
}

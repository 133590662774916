import useImageDownload from 'hooks/useImageDownload';

export default function useOlrImageDownload(
  imageBase64: string,
  imageLoading: boolean,
  model: string,
  wave: string,
) {
  const date = (new Date()).toLocaleDateString('pt-BR').replace(/\/|:|,|\s/g, '_');
  const filename = `olr_${model}_${wave}_${date}.png`;
  const {
    onDownloadClick,
    downloadDisabled,
  } = useImageDownload(imageBase64, imageLoading, filename);

  return {
    onOlrDownloadClick: onDownloadClick,
    olrDownloadDisabled: downloadDisabled,
  };
}

import { ResponseError } from 'services/api/base';
import { useGetTropicoOptionsQuery } from 'services/api/Meteorology/Weather/Area/Status/Tropico';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useTropicoOptions() {
  const { data, error, isLoading } = useGetTropicoOptionsQuery();

  useHandleRequestErrors(error as ResponseError);

  return { data, isLoading };
}

import downloadFile from 'helper/downloadFile';

export default function useImageDownload(
  imageBase64: string,
  imageLoading: boolean,
  filename: string,
) {
  const downloadDisabled = imageLoading || !imageBase64;
  const onDownloadClick = () => {
    if (!downloadDisabled) {
      downloadFile(imageBase64, filename);
    }
  };

  return {
    onDownloadClick,
    downloadDisabled,
  };
}

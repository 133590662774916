import { useState } from 'react';
import { fromUnixTime, getUnixTime } from 'date-fns';
import { store } from 'redux/store';
import { reset, setShowSuccessModal } from 'redux/reducers/fluxPersonalizedFormReducer';
import { ResponseError } from 'services/api/base';
import { useCreatePersonalizedRequestMutation, usePersonalizedExecutionQueueMutation } from 'services/api/personalized';
import { CustomAlert } from 'components/AlertsCollection';
import { PersonalizedScenarioDraft } from 'helper/personalized';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import useNewStudyForm from './useNewStudyForm';

const NAME_CHECK = /^[A-Z0-9-]+$/;

export default function useSendStudy(scenarios: PersonalizedScenarioDraft[]) {
  const [validated, setValidated] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [alerts, setAlerts] = useState<CustomAlert[]>([]);

  const [saveTrigger, { error: saveError }] = useCreatePersonalizedRequestMutation();
  const [sendTrigger, { error: sendError }] = usePersonalizedExecutionQueueMutation();
  useHandleRequestErrors(saveError as ResponseError);
  useHandleRequestErrors(sendError as ResponseError);

  const {
    name, startDate, endDate, multiScenarios, previvazModel,
    outputOptions, groupNumber, flowPercentileValue,
  } = useNewStudyForm();
  const studyEndDate = fromUnixTime(endDate);

  const cenarios = scenarios.map((scenario) => ({
    ds_nome: scenario.name,
    blocos: scenario.blocks.map((block) => ({
      ds_modelo: block.model,
      dt_data_prev: getUnixTime(block.forecastDate),
      ck_rmv: Boolean(block.rmv),
      dt_inicio: getUnixTime(block.startDate),
      dt_fim: getUnixTime(block.endDate),
      dt_recorte_inicio: getUnixTime(block.sliceStartDate),
      dt_recorte_fim: getUnixTime(block.sliceEndDate),
      membro: block.member.toString(),
      run_time: block.runtime.toString(),
    })),
  }));

  const saveParams = {
    ready: true,
    vl_execution_counter: 0,
    ds_nome_estudo: name,
    dt_inicio: startDate,
    dt_fim: endDate,
    flag_produzir_mapas: outputOptions.includes('Produzir mapas de preciptação'),
    flag_produzir_arquivo_pmed_xlsx: outputOptions.includes('Produzir arquivo excel PMED'),
    flag_produzir_arquivos_dessem: outputOptions.includes('Produzir arquivos DESSEM'),
    flag_produzir_ena_diaria: outputOptions.includes('Produzir arquivo.csv de ENA diária'),
    flag_tabelar_prevs_produzidos: outputOptions.includes('Produzir memória de cálculo ENA semanal'),
    flag_produzir_prevs_mensais: outputOptions.includes('Produzir arquivos PREVS.RVX mensais'),
    flag_produzir_vazpast: outputOptions.includes('Produzir arquivos VAZPAST.DAT'),
    flag_produzir_todas_revisoes_intermediarias: previvazModel === 'Produzir todas as revisões',
    flag_produzir_somente_rv0_intermediarias: previvazModel === 'Produzir somente RV0/RVF/RVXmax',
    flag_produzir_somente_revisoes_rvf: previvazModel === 'Produzir RVF/RVXmax',
    flag_produzir_vmed: multiScenarios.includes('Produzir vazão média'),
    flag_produzir_vpercentil: multiScenarios.includes('Produzir percentis de vazão'),
    cenarios_vpercentil: multiScenarios.includes('Produzir percentis de vazão') ? flowPercentileValue : '',
    ck_numero_agrupamentos: multiScenarios.includes('Predefinir número de agrupamentos'),
    flag_produzir_cenarios_por_agrupamento: multiScenarios.includes('Produzir vazão por agrupamento:'),
    numero_agrupamentos: groupNumber ? groupNumber.toString() : '',
    ck_dimensoes_para_agrupamento: multiScenarios.includes('Definir dimensões para agrup.'),
    se_ne_v_s_n: multiScenarios.includes('SE+NE versus S+N'),
    se_s_v_s_ne_n: multiScenarios.includes('SE+S versus NE+N'),
    se_n_v_s_ne: multiScenarios.includes('SE+N versus S+NE'),
    se_v_s_ne_n: multiScenarios.includes('SE versus S+NE+N'),
    se_v_s_ne_s_n: multiScenarios.includes('SE vs S vs NE vs N'),
    cenarios,
  };

  const onSendClick = async () => {
    const validityAlerts: CustomAlert[] = [];

    if (!NAME_CHECK.test(name)) {
      validityAlerts.push({
        title: 'Nome do estudo inválido',
        message: 'Nome deve ter apenas letras maiúsculas, números e hífens.',
        variant: 'warning',
      });
    }

    scenarios.forEach((scenario) => {
      const lastBlock = scenario.blocks.at(-1);
      if (!NAME_CHECK.test(scenario.name)) {
        validityAlerts.push({
          title: 'Nome de cenário inválido',
          message: 'Nome de cenário deve ter apenas letras maiúsculas, números e hífens.',
          variant: 'warning',
        });
      }
      if (lastBlock === undefined) {
        validityAlerts.push({
          title: 'Bloco vazio',
          message: 'Bloco do cenário não pode ser vazio.',
          variant: 'warning',
        });
      } else if (lastBlock.endDate < studyEndDate) {
        validityAlerts.push({
          title: 'Datas nos blocos inconsistentes',
          message: 'Data final em blocos de cenário deve ser maior que a do estudo.',
          variant: 'warning',
        });
      }
    });

    if (validityAlerts.length > 0) {
      setValidated(true);
      setAlerts(validityAlerts);
      return;
    }

    setSendLoading(true);

    const saveResponse = await saveTrigger(saveParams);

    if ('data' in saveResponse) {
      const study = saveResponse.data.data;
      const sendResponse = await sendTrigger(study.id);

      if ('data' in sendResponse) {
        if (sendResponse.data.code === 200) {
          store.dispatch(reset());
          store.dispatch(setShowSuccessModal(true));
        } else {
          const { title, message } = sendResponse.data;
          setAlerts([{ title, message, variant: 'danger' }]);
        }
      }
    }

    setSendLoading(false);
  };

  return {
    onSendClick,
    validated,
    sendLoading,
    alerts,
  };
}

import React, { useState } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import ReactECharts from 'echarts-for-react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HistoryRange from 'types/Meteorology/Weather/Area/historyRange';
import downloadFile from 'helper/downloadFile';
import { filterHistoryData } from 'helper/Meteorology/Weather/Area/History';
import range from 'helper/range';
import useParseHistoryCategories from 'hooks/Meteorology/Weather/Area/History/useParseHistoryCategories';
import DataRangeDisplay from '../../DataRangeDisplay';
import RangeSelector from '../../RangeSelector';

export default function MJOHistoryCard({
  amplitudeCategories,
  amplitudeValues,
  phaseCategories,
  phaseValues,
}: {
  amplitudeCategories: string[],
  amplitudeValues: number[],
  phaseCategories: string[],
  phaseValues: number[],
}) {
  const [selectedRange, setSelectedRange] = useState<HistoryRange>('all');
  const { filteredCategories, filteredValues: filteredAmplitudeValues } = filterHistoryData(
    new Date(),
    selectedRange,
    amplitudeValues,
    amplitudeCategories,
  );
  const { filteredValues: filteredPhaseValues } = filterHistoryData(
    new Date(),
    selectedRange,
    phaseValues,
    phaseCategories,
  );
  const { categories } = useParseHistoryCategories(filteredCategories);
  const rowsAvailable = Math.min(
    filteredCategories.length,
    filteredAmplitudeValues.length,
    filteredPhaseValues.length,
  );
  const downloadCsv = () => {
    const rows = range(rowsAvailable).map((i) => (
      `${filteredCategories[i]};${filteredAmplitudeValues[i].toLocaleString('pt-BR')};${filteredPhaseValues[i].toLocaleString('pt-BR')}`
    ));
    const csvData = btoa(`Data;Amplitude;Fase\n${rows.join('\n')}`);
    downloadFile(csvData, `historico_${selectedRange}.csv`);
  };

  return (
    <Card className="shadow-sm forecast-instruction-card m-5">
      <Col className="flex-fill py-5 h-100">
        <div className="d-flex px-5 justify-content-between align-items-center">
          <div className="text-primary">Amplitude/Fase - Oscilação Madden-Julian (MJO)</div>
          <div className="text-primary fw-bold d-flex align-items-center">
            Baixar dados em CSV
            <Button
              id="weather-history-download"
              size="sm"
              className="icon-button download-button ms-3"
              disabled={rowsAvailable < 1}
              onClick={downloadCsv}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </div>
        </div>
        <div className="mt-5">
          <div className="d-flex justify-content-between align-items-center px-5">
            <RangeSelector
              selection={selectedRange}
              setSelection={setSelectedRange}
            />
            <DataRangeDisplay
              firstDate={amplitudeCategories.at(0) || ''}
              lastDate={amplitudeCategories.at(-1) || ''}
            />
          </div>
          <div id="weather-history-chart" data-start={filteredCategories.at(0)} data-end={filteredCategories.at(-1)}>
            <ReactECharts
              opts={{ locale: 'pt_BR' }}
              option={{
                grid: [
                  {
                    left: '4%',
                    right: '4%',
                    height: '25%',
                  },
                  {
                    left: '4%',
                    right: '4%',
                    top: '60%',
                    height: '25%',
                  },
                ],
                xAxis: [
                  {
                    type: 'time',
                    splitNumber: 10,
                    boundaryGap: false,
                    data: filteredCategories,
                    show: false,
                  },
                  {
                    gridIndex: 1,
                    type: 'time',
                    splitNumber: 10,
                    boundaryGap: false,
                    data: filteredCategories,
                  },
                ],
                yAxis: [
                  {
                    name: 'Amplitude',
                    type: 'value',
                  },
                  {
                    gridIndex: 1,
                    name: 'Fase',
                    type: 'value',
                  },
                ],
                legend: {
                  data: ['Amplitude', 'Fase'],
                },
                series: [
                  {
                    name: 'Amplitude',
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    data: categories.map((date, index) => ({
                      name: date,
                      value: [date, filteredAmplitudeValues[index]],
                    })),
                  },
                  {
                    name: 'Fase',
                    type: 'line',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    symbol: 'none',
                    data: categories.map((date, index) => ({
                      name: date,
                      value: [date, filteredPhaseValues[index]],
                    })),
                  },
                ],
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    animation: false,
                  },
                },
                axisPointer: {
                  link: [{ xAxisIndex: 'all' }],
                },
                animation: false,
                dataZoom: [
                  {
                    type: 'slider',
                    show: 'true',
                    xAxisIndex: [0, 1],
                    top: '95%',
                    start: 0,
                    end: 100,
                    filterMode: 'none',
                    borderRadius: 32,
                    height: 9,
                    borderColor: '#c1c1c1',
                    fillerColor: '#26548a',
                    showDetail: false,
                    showDataShadow: false,
                    handleIcon: 'path://m50,50 a100,100 0 1,0 -200,0 a 100,100 0 1,0 200,0 Z',
                    handleStyle: {
                      borderColor: '#26548a',
                      borderWidth: 2,
                    },
                    moveHandleStyle: {
                      opacity: 0,
                    },
                    brushSelect: false,
                    brushStyle: {
                      opacity: 0,
                    },
                    dataBackground: {
                      lineStyle: {
                        opacity: 0,
                      },
                      areaStyle: {
                        opacity: 0,
                      },
                    },
                  },
                  {
                    type: 'slider',
                    show: 'true',
                    yAxisIndex: 0,
                    right: '3%',
                    start: 0,
                    end: 100,
                    filterMode: 'none',
                    borderRadius: 32,
                    width: 9,
                    borderColor: '#c1c1c1',
                    fillerColor: '#26548a',
                    showDetail: false,
                    showDataShadow: false,
                    handleIcon: 'path://m50,50 a100,100 0 1,0 -200,0 a 100,100 0 1,0 200,0 Z',
                    handleStyle: {
                      borderColor: '#26548a',
                      borderWidth: 2,
                    },
                    moveHandleStyle: {
                      opacity: 0,
                    },
                    brushSelect: false,
                    brushStyle: {
                      opacity: 0,
                    },
                    dataBackground: {
                      lineStyle: {
                        opacity: 0,
                      },
                      areaStyle: {
                        opacity: 0,
                      },
                    },
                  },
                  {
                    type: 'slider',
                    show: 'true',
                    yAxisIndex: 1,
                    right: '3%',
                    start: 0,
                    end: 100,
                    filterMode: 'none',
                    borderRadius: 32,
                    width: 9,
                    borderColor: '#c1c1c1',
                    fillerColor: '#26548a',
                    showDetail: false,
                    showDataShadow: false,
                    handleIcon: 'path://m50,50 a100,100 0 1,0 -200,0 a 100,100 0 1,0 200,0 Z',
                    handleStyle: {
                      borderColor: '#26548a',
                      borderWidth: 2,
                    },
                    moveHandleStyle: {
                      opacity: 0,
                    },
                    brushSelect: false,
                    brushStyle: {
                      opacity: 0,
                    },
                    dataBackground: {
                      lineStyle: {
                        opacity: 0,
                      },
                      areaStyle: {
                        opacity: 0,
                      },
                    },
                  },
                ],
              }}
            />
          </div>
        </div>
      </Col>
    </Card>
  );
}

import { Invoice } from 'helper/financial';
import { useState } from 'react';
import parse from 'date-fns/parse';

export default function useInvoiceSelection() {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState([] as Invoice[]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const toggleInvoiceSelection = (invoice: Invoice) => () => {
    let newSelectedInvoices: Invoice[];

    if (selectedInvoices.includes(invoice)) {
      newSelectedInvoices = selectedInvoices.filter((element) => element !== invoice);
    } else {
      newSelectedInvoices = [...selectedInvoices, invoice];
    }

    setSelectedInvoices(newSelectedInvoices);
    setStartDate(null);
    setEndDate(null);
  };

  const selectAll = () => {
    setSelectedInvoices(invoices);
    setStartDate(null);
    setEndDate(null);
  };

  const selectNone = () => {
    setSelectedInvoices([]);
    setStartDate(null);
    setEndDate(null);
  };

  const onDateChange = (dates: (Date | null)[]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setSelectedInvoices(invoices.filter((invoice) => {
        const issueDate = parse(invoice.issueDate, 'dd/MM/yyyy', start);
        return issueDate >= start && issueDate <= end;
      }));
    }
  };

  const allSelected = selectedInvoices.length !== 0 && invoices.length === selectedInvoices.length;
  const noneSelected = selectedInvoices.length === 0;

  return {
    invoices,
    setInvoices,
    selectedInvoices,
    toggleInvoiceSelection,
    allSelected,
    noneSelected,
    selectAll,
    selectNone,
    startDate,
    endDate,
    onDateChange,
  };
}

import React, { useState } from 'react';
import useTabData from 'hooks/Flux/About/useTabData';
import { Row, Tab, Tabs } from 'react-bootstrap';
import CentralizedSpinner from 'components/CentralizedSpinner';
import AboutSection from 'components/Flux/About/AboutSection';
import './index.scss';

export default function About() {
  const { instructionTabs, isLoading } = useTabData();
  const [key, setKey] = useState<string | null>(null);

  return (
    <>
      <Row>
        <h1>SOBRE</h1>
      </Row>
      {isLoading && <CentralizedSpinner />}
      {instructionTabs?.length && (
        <Row className="flux-about-tab-container">
          <Tabs
            activeKey={key || instructionTabs[0].id}
            onSelect={(k) => setKey(k)}
            transition={false}
            id="flux-about-tabs"
            className="tabbed-page gap-1"
          >
            {instructionTabs.map((tab) => (
              <Tab
                key={tab.id}
                eventKey={tab.id}
                title={tab.title}
                className="p-4"
              >
                <AboutSection tab={tab} activeKey={key || String(instructionTabs[0].id)} />
              </Tab>
            ))}
          </Tabs>
        </Row>
      )}
    </>
  );
}

import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import RightSidebar from 'components/Layout/RightSidebar';
import { useDispatch, useSelector } from 'react-redux';
import usePermissionCheck from 'utils/hooks/usePermissionCheck';
import CentralizedSpinner from 'components/CentralizedSpinner';
import ControlPanel from 'components/Flux/Automatic/ControlPanel';
import TabbedPage from 'components/TabbedPage';
import { openRight, selectOpen } from 'redux/reducers/sidebarsReducer';

export default function FluxAutomaticWrapper() {
  const dispatch = useDispatch();
  const openSide = useSelector(selectOpen);
  const keySaved = usePermissionCheck('prevs-automatico');
  const [contentWidth, panelWidth] = openSide === 'right'
    ? ['66.66666667%', '33.33333333%']
    : ['95%', '5%'];

  useEffect(() => {
    if (keySaved) dispatch(openRight());
  }, [dispatch, keySaved]);

  if (!keySaved) {
    return <CentralizedSpinner />;
  }

  return (
    <>
      <Row>
        <Col className="md-6">
          <h1 className="mb-3">ESTUDOS AUTOMÁTICOS</h1>
        </Col>
      </Row>
      <Row style={{ flexWrap: 'nowrap', paddingBottom: '3.3125rem' }}>
        <Col className="flex-fill" style={{ maxWidth: contentWidth }}>
          <TabbedPage>
            <TabbedPage.Tab title="CLÁSSICO" path="/flux/automatic/classic" />
            <TabbedPage.Tab title="BASE DIÁRIA" path="/flux/automatic" />
            <TabbedPage.Tab title="BASE MENSAL" path="/flux/automatic/monthly" />
            <TabbedPage.Tab title="REVISÃO POR REVISÃO" path="/flux/automatic/revision" />
          </TabbedPage>
        </Col>
        <Col style={{ maxWidth: panelWidth }}>
          <RightSidebar>
            <ControlPanel />
          </RightSidebar>
        </Col>
      </Row>
      <div className="bottom-bar" />
    </>
  );
}

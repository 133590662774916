import React, { useState, useEffect } from 'react';
import {
  Accordion, Row, Button, Spinner, Form,
} from 'react-bootstrap';
import { useDeletePntPreferencesMutation, useListPntPreferencesQuery } from 'services/api/Meteorology';
import { useHandleRequestErrors } from 'utils/hooks';
import { ResponseError } from 'services/api/base';
import dateDiffToDate from 'helper/dateDiffToDate';
import useFromUnixTime from 'utils/hooks/useFromUnixTime';
import useNumericForecastCP from 'utils/hooks/meteorology/useNumericForecastControlPanel';
import useSavePreferencesModal from 'utils/hooks/meteorology/useSavePreferencesModal';
import useControlPanelOptions from 'utils/hooks/meteorology/useControlPanelOptions';
import DatePicker from 'components/DatePicker';
import PreferencesAccordion from '../PreferencesAccordion';
import RegionButton from './RegionButton';
import PeriodButton from './PeriodButton';
import ModelCheckbox from './ModelCheckbox';
import SavePreferencesModal from './SavePreferencesModal';
import ConfirmationModal from './confirmationModal';
import ErrorModal from './errorModal';

export default function NumericForecast() {
  const { models, isFetching } = useControlPanelOptions('pnt');
  const {
    setShowFormModal,
    isShowingConfirmationModal,
    setShowConfirmationModal,
    isShowingErrorModal,
    setShowErrorModal,
    error: saveError,
  } = useSavePreferencesModal();
  const { data, error } = useListPntPreferencesQuery();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const preferenceList = data?.data || [];
  const [onPreferenceDeleteTrigger, {
    isLoading: preferenceDeleteLoading,
    error: preferenceDeleteError,
  }] = useDeletePntPreferencesMutation();
  useHandleRequestErrors(error as ResponseError | undefined);
  useHandleRequestErrors(preferenceDeleteError as ResponseError | undefined);

  const {
    forecastUnixDate,
    setForecastUnixDate,
    resetPreferences,
    setPeriod,
    setModelArray,
    setRegion,
    anomaly,
    setAnomaly,
    controlPanelUsed,
  } = useNumericForecastCP();
  const selectedDate = useFromUnixTime(forecastUnixDate);

  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    if (data === undefined || !firstLoad) {
      return;
    }
    setFirstLoad(false);
    if (preferenceList.length === 0 || controlPanelUsed) {
      return;
    }
    const preference = preferenceList[0];
    const today = new Date();
    const date = dateDiffToDate(preference.json.data_prev, [today]) || today;

    resetPreferences();
    setPeriod(preference.json.periodo_interesse);
    setModelArray(preference.json.modelos);
    setRegion(preference.json.area_plotagem);
    setAnomaly(preference.json.anom);
    setForecastUnixDate(date);
  }, [firstLoad,
    data,
    preferenceList,
    resetPreferences,
    setForecastUnixDate,
    setModelArray,
    setPeriod,
    setRegion,
    setAnomaly,
    controlPanelUsed,
  ]);

  const clearModelSelections = () => {
    setModelArray([]);
  };

  if (isFetching) {
    return (
      <div className="text-center py-3">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <ConfirmationModal
        open={isShowingConfirmationModal}
        closing={() => { setShowConfirmationModal(false); }}
      />
      <ErrorModal
        open={isShowingErrorModal}
        message={saveError}
        closing={() => { setShowErrorModal(false); }}
      />
      <SavePreferencesModal />
      <Accordion defaultActiveKey="0">
        <PreferencesAccordion
          preferenceList={preferenceList}
          deleteTrigger={onPreferenceDeleteTrigger}
          deleteLoading={preferenceDeleteLoading}
        />
        <Accordion.Item eventKey="1" className="control-panel-item">
          <Accordion.Header>Área de plotagem</Accordion.Header>
          <Accordion.Body className="d-grid">
            <RegionButton text="Brasil" value="BR" />
            <RegionButton text="Sul" value="S" />
            <RegionButton text="Sudeste" value="SE" />
            <RegionButton text="Centro-Oeste" value="CO" />
            <RegionButton text="Nordeste" value="NE" />
            <RegionButton text="Norte" value="N" />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="control-panel-item">
          <Accordion.Header>Modelo</Accordion.Header>
          <Accordion.Body className="d-grid control-panel-columns">
            {models.map((m) => (
              <ModelCheckbox key={m.value} value={m.value} label={m.label} />
            ))}
            <Button variant="link" className="btn-sm text-start" onClick={clearModelSelections}>Nenhum</Button>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="control-panel-item">
          <Accordion.Header>Data de previsão</Accordion.Header>
          <Accordion.Body className="d-grid">
            <DatePicker
              inline
              selected={selectedDate}
              onChange={setForecastUnixDate}
              maxDate={new Date()}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" className="control-panel-item">
          <Accordion.Header>Período de seleção</Accordion.Header>
          <Accordion.Body className="d-grid">
            <PeriodButton text="Diário" value="d+0" />
            <PeriodButton text="Pentada" value="p+0" />
            <PeriodButton text="Semanal" value="s+0" />
            <PeriodButton text="Mensal" value="m+0" />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Form.Check
        id="anomaly-checkbox"
        className="mt-4"
        type="checkbox"
        label="Anomalia"
        checked={anomaly}
        onChange={() => { setAnomaly(!anomaly); }}
      />
      <Row className="pt-5">
        <Button
          variant="primary"
          onClick={() => {
            setShowFormModal(true);
          }}
        >
          Salvar nova preferência
        </Button>
      </Row>
      <Row>
        <Button variant="link" className="clear-filters" onClick={resetPreferences}>
          Limpar filtros
        </Button>
      </Row>
    </div>
  );
}

import { store } from 'redux/store';
import { selectMeteorologyKey } from 'redux/reducers/productsReducer';
import baseApi, { GenericResponse } from 'services/api/base';
import { Overview } from 'types/Meteorology/Weather/Overview';
import { GetOverviewParams } from 'types/Meteorology/Weather/Overview/getOverviewParams';
import {
  transformGetOverviewResponse,
  transformGetLatestReportPathReponse,
  transformGetOverviewOptionsResponse,
} from 'helper/Meteorology/Weather/Overview';
import {
  EditOverviewSummaryParams,
} from 'types/Meteorology/Weather/Overview/editOverviewSummaryParams';
import filterUndefined from 'utils/filterUndefined';
import { OverviewOption } from 'types/Meteorology/Weather/Overview/getOverviewOptionsResponse';

export const weatherOverviewApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOverview: build.query<Overview, GetOverviewParams>({
      transformResponse: transformGetOverviewResponse,
      query: ({ period }) => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/overview/?product_key=${productKey}`,
          method: 'POST',
          body: filterUndefined({
            periodo: period,
          }),
        };
      },
    }),
    getLatestReportPath: build.query<string, void>({
      transformResponse: transformGetLatestReportPathReponse,
      query: () => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/overview-latest-video/?product_key=${productKey}`,
          method: 'GET',
        };
      },
    }),
    editOverviewSummary: build.mutation<GenericResponse<null>, EditOverviewSummaryParams>({
      query: ({ summary }) => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/edit-descricao-overview/?product_key=${productKey}`,
          method: 'POST',
          body: filterUndefined({
            descricao: summary,
          }),
        };
      },
    }),
    getOverviewOptions: build.query<OverviewOption[], void>({
      transformResponse: transformGetOverviewOptionsResponse,
      query: () => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/options_overview/?product_key=${productKey}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetOverviewQuery,
  useLazyGetOverviewQuery,
  useGetLatestReportPathQuery,
  useEditOverviewSummaryMutation,
  useGetOverviewOptionsQuery,
} = weatherOverviewApi;

import { ResponseError } from 'services/api/base';
import { useGetModelOptionsQuery } from 'services/api/Meteorology/Weather/Area/Status';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useModelOptions() {
  const {
    data, error, isLoading,
  } = useGetModelOptionsQuery();

  useHandleRequestErrors(error as ResponseError);

  return { modelOptions: data || [], modelOptionsLoading: isLoading };
}

import useInstructionTabs from './useInstructionTabs';

export default function useTabData() {
  const { instructionTabs, isLoading } = useInstructionTabs();

  return {
    instructionTabs,
    isLoading,
  };
}

import useImageDownload from 'hooks/useImageDownload';

export default function usePredictionImageDownload(
  imageBase64: string,
  imageLoading: boolean,
  area: string,
  model: string,
  period: string,
) {
  const date = (new Date()).toLocaleDateString('pt-BR').replace(/\/|:|,|\s/g, '_');
  const filename = `prediction_${area}_${model}_${period}_${date}.png`;
  const {
    onDownloadClick,
    downloadDisabled,
  } = useImageDownload(imageBase64, imageLoading, filename);

  return {
    onPredictionDownloadClick: onDownloadClick,
    downloadDisabled,
  };
}

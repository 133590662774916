import React from 'react';
import { Card, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { negativePolarity, positivePolarity } from 'hooks/Meteorology/Weather/Area/About/usePolaritySelection';
import EnlargeableImage from 'components/Meteorology/Fields/SynopticCharts/SynopticChart/Map/EnlargeableImage';

export default function ImagePanel({
  name,
  imageSrc,
  showPolaritySelector,
  polarity,
  handlePolarityChange,
}: {
  name: string,
  imageSrc: string,
  showPolaritySelector: boolean,
  polarity: string | undefined,
  handlePolarityChange: (polarity: string) => void,
}) {
  return (
    <Card className="shadow-sm forecast-instruction-card h-100 p-3">
      <div className="d-flex justify-content-between card-header border-0 mt-1">
        <div className="text-start">{name}</div>
        {showPolaritySelector && (
          <ToggleButtonGroup
            name="polarity-toggle"
            type="radio"
            value={polarity}
            onChange={handlePolarityChange}
          >
            <ToggleButton
              id="polarity-pos"
              value={positivePolarity}
              className="weather-toggle-button mx-1"
            >
              POSITIVE
            </ToggleButton>
            <ToggleButton
              id="polarity-neg"
              value={negativePolarity}
              className="weather-toggle-button mx-1"
            >
              NEGATIVE
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </div>
      <Card.Body>
        <EnlargeableImage src={imageSrc} className="mt-3" />
      </Card.Body>
    </Card>
  );
}

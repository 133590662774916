import { useEffect, useState } from 'react';
import useControlPanelOptions from 'utils/hooks/meteorology/useControlPanelOptions';
import { SpecialModels, NA_VALUE, LockedRMVModels } from 'helper/personalized';

export default function useBlockOptions() {
  const [model, setModel] = useState('');
  const [runtime, setRuntime] = useState<number>(-1);
  const [member, setMember] = useState('');
  const [rmv, setRmv] = useState(-1);

  const { models, getModelOptions } = useControlPanelOptions('flux');
  const runtimeOptions = getModelOptions(model).runtimes;
  const memberOptions = getModelOptions(model).members;

  const onModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setModel(e.target.value);
  };
  const onRuntimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRuntime(Number(e.target.value));
  };
  const onMemberChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMember(e.target.value);
  };

  const specialModelSelected = Object.values(SpecialModels).includes(model as SpecialModels);
  const lockedRMVModelselected = Object.values(LockedRMVModels).includes(model as LockedRMVModels);
  const runtimeDisabled = runtimeOptions.length === 0 || specialModelSelected;
  const modelWithoutMember = specialModelSelected && model !== SpecialModels.Climatology;
  const memberDisabled = memberOptions.length === 0 || modelWithoutMember;
  const rmvDisabled = lockedRMVModelselected;

  useEffect(() => {
    if (specialModelSelected) {
      setRuntime(NA_VALUE);
      setMember(NA_VALUE.toString());
    }
  }, [specialModelSelected]);

  useEffect(() => {
    setRmv(lockedRMVModelselected ? 0 : -1);
  }, [lockedRMVModelselected]);

  return {
    model,
    runtime,
    member,
    rmv,
    modelOptions: models,
    runtimeOptions,
    memberOptions,
    onModelChange,
    onRuntimeChange,
    onMemberChange,
    setRmv,
    runtimeDisabled,
    memberDisabled,
    rmvDisabled,
  };
}

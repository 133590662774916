import { useEffect } from 'react';
import { ResponseError } from 'services/api/base';
import { useLazyGetMJOPredictionQuery } from 'services/api/Meteorology/Weather/Area/Status/MJO';
import { GetMJOPredictionParams } from 'types/Meteorology/Weather/Area/Status/MJO/getMJOPredictionParams';
import { useHandleRequestErrors } from 'utils/hooks';

export default function useMJOPrediction(params: GetMJOPredictionParams) {
  const [trigger, { data, error, isLoading }] = useLazyGetMJOPredictionQuery();
  const { model, variable } = params;

  useEffect(() => {
    if (model && variable) {
      trigger({ model, variable });
    }
  }, [model, variable, trigger]);

  useHandleRequestErrors(error as ResponseError);

  return {
    predictionImageBase64: data?.imageBase64 || '',
    predictionImageLoading: isLoading,
  };
}

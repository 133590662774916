import React from 'react';
import { Card, FormSelect, Button } from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOptionSelection from 'hooks/useOptionSelection';
import useDatedImageDownload from 'hooks/useDatedImageDownload';
import useMJOPrediction from 'hooks/Meteorology/Weather/Area/Status/MJO/useMJOPrediction';
import CentralizedSpinner from 'components/CentralizedSpinner';
import EnlargeableImage from 'components/Meteorology/Fields/SynopticCharts/SynopticChart/Map/EnlargeableImage';

export default function PredictionPanel({
  models, variables,
}: {
  models: string[], variables: string[],
}) {
  const {
    option: model,
    handleOptionChange: handleModelChange,
  } = useOptionSelection();
  const {
    option: variable,
    handleOptionChange: handleVariableChange,
  } = useOptionSelection();
  const { predictionImageBase64, predictionImageLoading } = useMJOPrediction({ model, variable });
  const filename = `mjo_prediction_${model}_${variable}`;
  const {
    onDownloadClick,
    downloadDisabled,
  } = useDatedImageDownload(predictionImageBase64, predictionImageLoading, filename);

  return (
    <Card className="shadow-sm forecast-instruction-card h-100">
      <Card.Header>
        PREVISÃO ESPACIAL
      </Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-between mt-3 me-3 align-items-center">
          <FormSelect
            id="predictionModelSelect"
            className="me-3"
            value={model}
            onChange={handleModelChange}
          >
            <option value="">Modelo</option>
            {models.map((option) => (
              <option value={option} key={option}>{option}</option>
            ))}
          </FormSelect>
          <FormSelect
            id="variableSelect"
            className="me-3"
            value={variable}
            onChange={handleVariableChange}
          >
            <option value="">Variável</option>
            {variables.map((option) => (
              <option value={option} key={option}>{option}</option>
            ))}
          </FormSelect>
          <Button
            id="predictionImageDownloadButton"
            size="sm"
            className="icon-button weather-download-button"
            onClick={onDownloadClick}
            disabled={downloadDisabled}
          >
            <FontAwesomeIcon icon={faDownload as IconProp} />
          </Button>
        </div>
        {predictionImageLoading && <CentralizedSpinner />}
        <EnlargeableImage src={`data:image/png;base64,${predictionImageBase64}`} className="mt-3" />
      </Card.Body>
    </Card>
  );
}

import { useEffect, useState } from 'react';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';

export default function useStatusPeriodSelection({
  area,
  setImage,
  triggerStatus,
}: {
  area: string,
  setImage: React.Dispatch<React.SetStateAction<string>>,
  triggerStatus: LazyQueryTrigger<any>,
}) {
  const [previousArea, setPreviousArea] = useState('');
  const [period, setPeriod] = useState('');

  const onPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setPeriod(value);
    if (area && value) {
      triggerStatus({ area, period: value });
    }
  };

  useEffect(() => {
    if (previousArea !== area) {
      if (previousArea) {
        setPeriod('');
        setImage('');
      }
      setPreviousArea(area);
    }
  }, [area, previousArea, setImage]);

  return {
    period,
    onPeriodChange,
  };
}

import { ResponseError } from 'services/api/base';
import { useImpactReferenceQuery } from 'services/api/Meteorology/Weather/Area/References';
import ImpactReferenceParams from 'types/Meteorology/Weather/Area/getImpactReferenceParams';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useAreaReference(params: ImpactReferenceParams) {
  const {
    data, error, isLoading, isFetching,
  } = useImpactReferenceQuery(params);

  useHandleRequestErrors(error as ResponseError);

  return { data, isLoading, isFetching };
}

import React from 'react';
import RegularHistory from './RegularHistory';
import MJOHistory from './MJO';

export default function History({
  areaAcronym,
  areaLabel,
}: {
  areaAcronym: string,
  areaLabel: string,
}) {
  switch (areaAcronym) {
    case 'mjo':
      return <MJOHistory areaAcronym={areaAcronym} />;
    default:
      return <RegularHistory areaAcronym={areaAcronym} areaLabel={areaLabel} />;
  }
}

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  Col, Container, Row, Stack, Spinner,
} from 'react-bootstrap';
import SearchBar from 'components/SearchBar';
import AdminTable, { AdminTableType } from 'components/Admin/AdminTable';
import UserModal from 'components/Admin/Users/UserModal';
import NewUserButton from 'components/Admin/Users/NewUserButton';
import FilterButton from 'components/Filters/FilterButton';
import UserFilterModal from 'components/Admin/Users/UserFilterModal';
import { useListCompaniesQuery } from 'services/api/companies';
import { useListProductsQuery } from 'services/api/products/index';
import { UserModalFilters, modalToQueryFilters } from 'helper/userFilters';
import { userAppliedFilters } from 'helper/filterModals';
import { closeModal, openModal } from 'helper/modals';
import {
  useListUsersQuery,
  SortableField,
  SORTABLE_FIELDS,
} from 'services/api/users';
import useInfiniteScrollPaging from 'utils/useInfiniteScrollPaging';
import { checkDataCompletion, getDataLength } from 'helper/scrollStopper';
import { useSorting } from 'components/SortingArrow';
import useListEnd from 'utils/hooks/useListEnd';
import { useRefreshUsersOnSearch } from 'components/Admin/Users/utils';
import { handleApiError } from 'helper/error';
import { ResponseError } from 'services/api/base';

export default function Users() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchBarText, setSearchBarText] = useState('');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const {
    data: companiesData,
    isLoading: companiesLoading,
  } = useListCompaniesQuery();
  const {
    data: productsData,
    isLoading: productsLoading,
  } = useListProductsQuery();
  const [filters, setFilters] = useState({
    selectedProducts: [],
    selectedCompany: '',
    isEnabled: false,
    isDisabled: false,
    isExpiringIn30: false,
    isExpiringIn60: false,
  } as UserModalFilters);
  const appliedFilters = userAppliedFilters(
    productsData,
    companiesData,
    filters,
    setFilters,
    searchQuery,
    setSearchQuery,
    setSearchBarText,
  );
  const filterActive = appliedFilters.length > 0;

  const [maxLength, setMaxLength] = useState<number>(-1);
  const pageSize = 12;
  const { page, setPage } = useInfiniteScrollPaging();
  const pageLength = pageSize * page;
  const dataLength = getDataLength(maxLength, pageLength);
  const [
    sortBy,
    sortDirection,
    sorters,
  ] = useSorting<SortableField | undefined>(SORTABLE_FIELDS);
  const {
    data,
    error,
    isLoading,
    isFetching,
  } = useListUsersQuery({
    page: 1,
    pageSize: dataLength,
    nameContains: searchQuery,
    orderBy: sortBy,
    orderDirection: sortDirection,
    ...modalToQueryFilters(filters, companiesData),
  });
  const navigate = useNavigate();
  const { listEnded } = useListEnd(data, page, pageSize, isFetching);

  useEffect(() => {
    if (error) {
      handleApiError(navigate, error as ResponseError);
    }
  });

  const { isFiltering } = useRefreshUsersOnSearch({
    query: searchQuery,
    sortBy,
    sortDirection,
    ...modalToQueryFilters(filters, companiesData),
  }, setPage, isFetching);

  useEffect(() => {
    setMaxLength(-1);
  }, [filters, searchQuery]);

  useEffect(() => {
    if (checkDataCompletion(dataLength, isFetching, data)) {
      setMaxLength(dataLength);
    }
  }, [dataLength, data, isFetching]);

  if (isLoading || isFiltering) {
    return <Spinner className="mt-5 mx-auto" animation="border" />;
  }

  return (
    <div>
      <UserModal />
      <UserFilterModal
        open={filterModalOpen}
        closeModal={closeModal(setFilterModalOpen)}
        filters={filters}
        setFilters={setFilters}
        productsLoading={productsLoading}
        products={productsData}
        companiesLoading={companiesLoading}
        companies={companiesData}
      />
      <Container>
        <Row className={filterActive ? 'align-items-center' : 'align-items-center pb-4'}>
          <Col>
            <Stack direction="horizontal" gap={3}>
              <h1 className="mb-0">USUÁRIOS</h1>
              <FilterButton
                filterActive={filterActive}
                onClick={openModal(setFilterModalOpen)}
              />
            </Stack>
          </Col>
          <Col>
            <SearchBar
              text={searchBarText}
              setText={setSearchBarText}
              setSearchQuery={setSearchQuery}
            />
          </Col>
          <Col>
            <NewUserButton />
          </Col>
        </Row>

        {filterActive && (
          <div className="my-3">
            {appliedFilters}
          </div>
        )}

        <Row className={filterActive ? '' : 'pt-5'}>
          {isLoading || isFiltering ? <Spinner className="mt-5 mx-auto" animation="border" />
            : (
              <AdminTable
                contentType={AdminTableType.USERS}
                usersData={data}
                companiesData={undefined}
                isFetching={isFetching}
                sorters={sorters}
                sortBy={sortBy}
                sortDirection={sortDirection}
                listEnded={listEnded}
                onEditClick={undefined}
              />
            )}
        </Row>
      </Container>
    </div>
  );
}

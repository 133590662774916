import { ResponseError } from 'services/api/base';
import { useLazyDownloadBulletinQuery } from 'services/api/videos';
import downloadFile from 'helper/downloadFile';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import { VideoProduct, videoProductToPath, videoProductToPermission } from 'helper/videos';

export default function useBulletinDownload(id: number, product: VideoProduct) {
  const [trigger, { data, isLoading, error }] = useLazyDownloadBulletinQuery();
  const permissionType = videoProductToPermission[product];

  const onDownload = async () => {
    if (!data?.data) {
      const productPath = videoProductToPath[product];
      const downloadQueryResult = await trigger({ id, productPath, permissionType });
      const downloadData = downloadQueryResult.data?.data;
      if (downloadData) {
        downloadFile(downloadData.file_b64, downloadData.file_name);
      }
    } else {
      downloadFile(data?.data.file_b64, data?.data.file_name);
    }
  };

  useHandleRequestErrors(error as ResponseError);

  return {
    onDownload,
    downloadData: data,
    downloadLoading: isLoading,
  };
}

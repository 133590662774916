import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useRightCollapse from 'utils/hooks/sidebars/useRightCollapse';
import { useClosure } from 'utils/hooks';
import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RightSidebarProps { }

export default function RightSidebar({ children }: React.PropsWithChildren<RightSidebarProps>) {
  const [collapsed, setCollapsed] = useRightCollapse();
  const collapseIcon: IconProp = collapsed ? faChevronLeft : faChevronRight;

  return (
    <div>
      <button type="button" onClick={useClosure(setCollapsed, !collapsed)} className="btn btn-link right-sidebar-toggle">
        <FontAwesomeIcon icon={collapseIcon} />
        {!collapsed && <span className="ms-3">PAINEL DE CONTROLE</span>}
      </button>
      <div className={collapsed ? 'd-none' : ''}>
        {children}
      </div>
    </div>
  );
}

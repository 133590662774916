import React from 'react';

import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="p-5">
      <h1>Página não encontrada</h1>
      <p>A página que tentou acessar não existe.</p>
      <p>Caso acredite que ela deveria existir, por favor entre em contato.</p>
      <Link to="/home" className="text-dark">
        Voltar para a home
      </Link>
    </div>
  );
}

import aboutRouter from './AboutRouter';
import automaticRouter from './AutomaticRouter';
import personalizedRouter from './PersonalizedRouter';
import uploadRouter from './UploadRouter';

const fluxRouter = {
  path: 'flux',
  children: [
    automaticRouter,
    personalizedRouter,
    uploadRouter,
    aboutRouter,
  ],
};

export default fluxRouter;

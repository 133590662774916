import React from 'react';
import {
  Button,
  Image,
  Modal,
  Stack,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import useKeyPress from 'hooks/useKeyPress';

import './index.scss';

export default function ImageModal({
  src,
  show,
  onHide,
  toFirst,
  toPrevious,
  toNext,
  toLast,
  up,
  down,
}: {
  src: string,
  show: boolean,
  onHide: () => void,
  toFirst?: () => void,
  toPrevious?: () => void,
  toNext?: () => void,
  toLast?: () => void,
  up?: () => void;
  down?: () => void;
}) {
  const showVerticalButtons = Boolean(up || down);
  const showHorizontalButtons = Boolean(toFirst || toPrevious || toNext || toNext);

  useKeyPress(['a', 'ArrowLeft'], toPrevious || (() => { }));
  useKeyPress(['d', 'ArrowRight'], toNext || (() => { }));
  useKeyPress(['w', 'ArrowUp'], up || (() => { }));
  useKeyPress(['s', 'ArrowDown'], down || (() => { }));
  useKeyPress(['End'], toLast || (() => { }));
  useKeyPress(['Home'], toFirst || (() => { }));

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      className="image-modal"
    >
      {showVerticalButtons && (
        <Button variant="link" size="lg" disabled={up === undefined} onClick={up}>
          <FontAwesomeIcon className="synoptic-charts-slider-control-button" icon={faAngleUp} />
        </Button>
      )}
      <Stack direction="horizontal" className="image-container">
        {showHorizontalButtons && (
          <>
            <Button variant="link" size="lg" disabled={toFirst === undefined} onClick={toFirst}>
              <FontAwesomeIcon className="synoptic-charts-slider-control-button" icon={faAngleDoubleLeft} />
            </Button>
            <Button variant="link" size="lg" disabled={toPrevious === undefined} onClick={toPrevious}>
              <FontAwesomeIcon className="synoptic-charts-slider-control-button" icon={faAngleLeft} />
            </Button>
          </>
        )}
        <Image fluid alt="" src={src} className="image" />
        {showHorizontalButtons && (
          <>
            <Button variant="link" size="lg" disabled={toNext === undefined} onClick={toNext}>
              <FontAwesomeIcon className="synoptic-charts-slider-control-button" icon={faAngleRight} />
            </Button>
            <Button variant="link" size="lg" disabled={toLast === undefined} onClick={toLast}>
              <FontAwesomeIcon className="synoptic-charts-slider-control-button" icon={faAngleDoubleRight} />
            </Button>
          </>
        )}
      </Stack>
      {showVerticalButtons && (
        <Button variant="link" size="lg" disabled={down === undefined} onClick={down}>
          <FontAwesomeIcon className="synoptic-charts-slider-control-button" icon={faAngleDown} />
        </Button>
      )}
    </Modal>
  );
}

ImageModal.defaultProps = {
  toFirst: undefined,
  toPrevious: undefined,
  toNext: undefined,
  toLast: undefined,
  up: undefined,
  down: undefined,
};

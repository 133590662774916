import { useEffect } from 'react';
import { ResponseError } from 'services/api/base';
import { useLazyGetMJOStatusQuery } from 'services/api/Meteorology/Weather/Area/Status/MJO';
import { useHandleRequestErrors } from 'utils/hooks';

export default function useMJOStatus(model: string) {
  const [trigger, { data, error, isLoading }] = useLazyGetMJOStatusQuery();

  useHandleRequestErrors(error as ResponseError);

  useEffect(() => {
    if (model) {
      trigger({ model });
    }
  }, [model, trigger]);

  return {
    statusImageBase64: data?.imageBase64 || '',
    statusImageLoading: isLoading,
  };
}

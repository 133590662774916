import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack } from 'react-bootstrap';

import range from 'helper/range';
import { DisplayMode } from 'helper/products/synopticCharts';
import {
  selectDisplayMode,
  selectGifIndex,
  selectGifs,
  selectGifsAreLoading,
  setDisplayMode,
  setGifIndex,
} from 'redux/reducers/synopticChartsReducer';

import './index.scss';

export default function PentadSelector() {
  const dispatch = useDispatch();
  const data = useSelector(selectGifs);
  const isFetching = useSelector(selectGifsAreLoading);
  const selectedIndex = useSelector(selectGifIndex);
  const displayMode: DisplayMode = useSelector(selectDisplayMode);

  const dataIsAvailable = data && data.length > 0;
  const disabled = displayMode !== DisplayMode.Gif || isFetching || !dataIsAvailable;

  const handlePentadSelection = (i: number) => {
    dispatch(setGifIndex(i));
    dispatch(setDisplayMode(DisplayMode.Gif));
  };

  return (
    <Stack direction="horizontal" gap={2} className="justify-content-end">
      {range(3).map((i) => (
        <Button
          key={`gif-pentad-${i}`}
          onClick={() => handlePentadSelection(i)}
          className={`
            synoptic-charts-pentad-selector-button
            ${(i !== selectedIndex || disabled) ? 'unselected' : ''}
          `}
        >
          {`GIF Pêntada ${i + 1}`}
        </Button>
      ))}
    </Stack>
  );
}

import { useEffect } from 'react';

import { ResponseError } from 'services/api/base';
import { ComparisonPeriodsParams, useLazyGetComparisonImagesPeriodsQuery } from 'services/api/Meteorology';
import useDisplayErrorMessages from 'utils/hooks/useDisplayErrorMessages';
import { useSelector } from 'react-redux';
import {
  selectMFComparatorControlPanelTriggerIsActive,
} from 'redux/reducers/meteorologyFieldsComparatorControlPanelReducer';

const EMPTY_PERIODS = [];

export default function useComparatorPeriods(params: ComparisonPeriodsParams | undefined) {
  const triggerIsActive = useSelector(selectMFComparatorControlPanelTriggerIsActive);
  const [trigger, { data, error, isFetching }] = useLazyGetComparisonImagesPeriodsQuery();
  const dataAvailable = !isFetching && !error && data?.data;
  const periods = dataAvailable ? data.data : EMPTY_PERIODS;
  const errorAlerts = useDisplayErrorMessages(error as ResponseError);

  useEffect(() => {
    if (triggerIsActive && params) {
      trigger(params);
    }
  }, [params, trigger, triggerIsActive]);

  return {
    periods,
    isFetching,
    errorAlerts,
  };
}

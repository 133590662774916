import useImageDownload from './useImageDownload';

export default function useDatedImageDownload(
  imageBase64: string,
  imageLoading: boolean,
  filename: string,
  extension = 'png',
) {
  const date = (new Date()).toLocaleDateString('pt-BR').replace(/\/|:|,|\s/g, '_');
  const datedFilename = `${filename}_${date}.${extension}`;
  const {
    onDownloadClick,
    downloadDisabled,
  } = useImageDownload(imageBase64, imageLoading, datedFilename);

  return {
    onDownloadClick,
    downloadDisabled,
  };
}

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomAlert } from 'components/AlertsCollection';
import {
  selectChart,
  selectDate,
  selectRuntime,
  setGifIndex,
  setGifs,
  setGifsAreLoading,
} from 'redux/reducers/synopticChartsReducer';
import { useLazyGetGifsQuery } from 'services/api/Meteorology/synopticCharts';

const DEFAULT_ALERT: CustomAlert = {
  title: 'Indisponível',
  message: 'Não há nenhum GIF disponível para esta data.',
  variant: 'warning',
};

const fetchGif = async (gifUrl: string) => {
  const res = await fetch(gifUrl);
  const blob = await res.blob();
  return URL.createObjectURL(blob);
};

export default function useGetGifs() {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [trigger, { data: gifUrls, error, isFetching: urlsAreLoading }] = useLazyGetGifsQuery();
  const chart = useSelector(selectChart);
  const dateString = useSelector(selectDate);
  const runtime = useSelector(selectRuntime);

  const alert = error ? DEFAULT_ALERT : undefined;

  useEffect(() => {
    if (chart && dateString && runtime) {
      dispatch(setGifIndex(0));
      dispatch(setGifs(undefined));
      dispatch(setGifsAreLoading(true));
      trigger({ chartId: chart.id, dateString, runtimeId: runtime.id });
    }
  }, [chart, dateString, runtime, trigger, dispatch]);

  useEffect(() => {
    if (gifUrls) {
      setIsFetching(true);
      const fetchGifs = async () => {
        const promises = gifUrls.map((gifUrl) => fetchGif(gifUrl));
        const fetchedGifs = await Promise.all(promises);
        return fetchedGifs;
      };

      fetchGifs()
        .then((g) => dispatch(setGifs(g)))
        .finally(() => setIsFetching(false));
    }
  }, [gifUrls, dispatch]);

  useEffect(() => {
    dispatch(setGifsAreLoading(urlsAreLoading || isFetching));
  }, [urlsAreLoading, isFetching, dispatch]);

  return { alert };
}

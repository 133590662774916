import React, { useState } from 'react';
import HistoryRange from 'types/Meteorology/Weather/Area/historyRange';
import downloadFile from 'helper/downloadFile';
import range from 'helper/range';
import { filterHistoryData } from 'helper/Meteorology/Weather/Area/History';
import { Card, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import DataRangeDisplay from '../DataRangeDisplay';
import HistoryGraph from '../HistoryGraph';
import RangeSelector from '../RangeSelector';

interface HistoryCardProps {
  areaAcronym: string,
  areaLabel: string,
  valueData: number[],
  categoryData: string[],
  graphPositiveColor?: string,
}

export default function HistoryCard({
  areaAcronym,
  areaLabel,
  valueData,
  categoryData,
  graphPositiveColor,
}: HistoryCardProps) {
  const [selectedRange, setSelectedRange] = useState<HistoryRange>('all');
  const { filteredCategories, filteredValues } = filterHistoryData(
    new Date(),
    selectedRange,
    valueData,
    categoryData,
  );
  const rowsAvailable = Math.min(filteredCategories.length, filteredValues.length);
  const downloadCsv = () => {
    const rows = range(rowsAvailable).map((i) => `${filteredCategories[i]},${filteredValues[i]}`);
    const csvData = btoa(`Date,Value\n${rows.join('\n')}`);
    downloadFile(csvData, `historico_${selectedRange}.csv`);
  };

  return (
    <Card className="shadow-sm forecast-instruction-card m-5">
      <Col className="flex-fill py-5 h-100">
        <div className="d-flex px-5 justify-content-between align-items-center">
          <div className="text-primary">{areaLabel}</div>
          <div className="text-primary fw-bold d-flex align-items-center">
            Baixar dados em CSV
            <Button
              id="weather-history-download"
              size="sm"
              className="icon-button download-button ms-3"
              disabled={rowsAvailable < 1}
              onClick={downloadCsv}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </div>
        </div>
        <div className="mt-5">
          <div className="d-flex justify-content-between align-items-center px-5">
            <RangeSelector
              selection={selectedRange}
              setSelection={setSelectedRange}
            />
            <p className="text-primary">{`${areaLabel} (${areaAcronym.toUpperCase()}) - Mensal`}</p>
            <DataRangeDisplay
              firstDate={categoryData.at(0) || ''}
              lastDate={categoryData.at(-1) || ''}
            />
          </div>
          <HistoryGraph
            categoryData={filteredCategories}
            valueData={filteredValues}
            positiveColor={graphPositiveColor}
          />
        </div>
      </Col>
    </Card>
  );
}

HistoryCard.defaultProps = { graphPositiveColor: undefined };

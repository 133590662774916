import React, { useEffect } from 'react';
import { store } from 'redux/store';
import { setCurrentTab } from 'redux/reducers/fluxAutomaticLayoutReducer';
import { Alert } from 'react-bootstrap';
import useClassicTableData from 'hooks/Flux/Automatic/Classic/useClassicTableData';
import useFluxAutomaticZipDownload from 'utils/hooks/flux/automatic/useFluxAutomaticZipDownload';
import CentralizedSpinner from 'components/CentralizedSpinner';
import FluxAutomaticGeneralTable from 'components/Flux/GeneralTable';
import ClassicRevisionSelector from 'components/Flux/Automatic/ClassicRevisionSelector';
import './index.scss';

export default function Classic() {
  useEffect(() => {
    store.dispatch(setCurrentTab('classic'));
  });
  const {
    modelData,
    isFetching,
    model,
    forecastDateLabel,
    acomphDateLabel,
  } = useClassicTableData();
  const {
    DownloadZipButton,
    DownloadConfirmationDialog,
  } = useFluxAutomaticZipDownload({
    review: undefined,
    models: [model],
    unidade: '%mlt',
    pastSimulationNames: [],
    type: 'classic',
    spatialSubdivisionParam: {},
    forecastDate: forecastDateLabel,
    acomphDate: acomphDateLabel,
  });

  return (
    <div className="pt-4 px-5">
      <DownloadConfirmationDialog />
      <div className="d-flex flex-row justify-content-end align-items-center mb-3">
        <ClassicRevisionSelector />
        <div className="ms-3">
          <DownloadZipButton />
        </div>
      </div>
      {isFetching && <CentralizedSpinner />}
      {!isFetching && !modelData && <Alert variant="light">Nenhum dado para os filtros selecionados.</Alert>}
      {!isFetching && modelData && (
        <div className="automatic-table-container">
          {Object.keys(modelData).map((system) => (
            <FluxAutomaticGeneralTable
              data={modelData[system]}
              lastColAsPercent
              tableLabel={system}
              key={system}
            />
          ))}
        </div>
      )}
    </div>
  );
}

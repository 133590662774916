import { GenericResponse } from 'services/api/base';
import { GetInstructionTabsResponse } from 'types/Flux/About/getInstructionTabsResponse';

export const transformInstructionTabsResponse = (response: GetInstructionTabsResponse) => (
  Object.keys(response.data).map((key) => ({
    id: Number(key),
    title: response.data[key],
  }))
);

export const transformInstructionPdfResponse = (response: GenericResponse<string>) => (
  response.data ?? ''
);

import { useEffect, useState } from 'react';
import { ResponseError } from 'services/api/base';
import { useLazyGetAreaStatusQuery } from 'services/api/Meteorology/Weather/Area/Status';
import { useHandleRequestErrors } from 'utils/hooks';

export default function useAreaStatusImage() {
  const [image, setImage] = useState('');
  const [trigger, { data, error, isFetching }] = useLazyGetAreaStatusQuery();

  useEffect(() => {
    if (data && !isFetching) {
      setImage(data.statusImageBase64);
    }
  }, [data, isFetching]);

  useHandleRequestErrors(error as ResponseError);

  return {
    statusImageBase64: image,
    statusImageLoading: isFetching,
    setImage,
    triggerStatus: trigger,
  };
}

export default function usePeriodControls(
  currentPeriod: string,
  periodList: string[],
  setPeriod: (period: string) => void,
) {
  const currentPeriodIndex = periodList.indexOf(currentPeriod);
  const toFirst = () => {
    setPeriod(periodList[0]);
  };
  const toPrevious = () => {
    setPeriod(periodList[currentPeriodIndex - 1]);
  };
  const toNext = () => {
    setPeriod(periodList[currentPeriodIndex + 1]);
  };
  const toLast = () => {
    setPeriod(periodList[periodList.length - 1]);
  };
  const isFirstPeriod = currentPeriodIndex === 0;
  const isLastPeriod = currentPeriodIndex === periodList.length - 1;

  return {
    toFirst,
    toPrevious,
    toNext,
    toLast,
    isFirstPeriod,
    isLastPeriod,
  };
}

import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import About from 'components/Meteorology/Weather/Area/About';
import Status from 'components/Meteorology/Weather/Area/Status';
import References from 'components/Meteorology/Weather/Area/References';
import History from 'components/Meteorology/Weather/Area/History';
import { useClosure } from 'utils/hooks';
import './index.scss';

export default function Area({
  areaAcronym,
  areaLabel,
}: {
  areaAcronym: string,
  areaLabel: string,
}) {
  const [key, setKey] = useState('about');
  const showHistory = areaAcronym !== 'tropicos';
  const statusTitle = areaAcronym === 'mjo' ? 'Diagrama de Fases / Previsão' : 'Observado / Previsão';

  useEffect(() => {
    if (!showHistory && key === 'history') setKey('about');
  }, [showHistory, key, setKey]);

  return (
    <Tabs
      activeKey={key}
      onSelect={useClosure(setKey)}
      className="px-4 pt-4 justify-content-center weather-inner-tabs"
      id="weather-inner-tabs"
    >
      <Tab eventKey="about" title="Sobre">
        <About areaAcronym={areaAcronym} areaLabel={areaLabel} />
      </Tab>
      <Tab eventKey="status" title={statusTitle}>
        <Status area={areaAcronym} />
      </Tab>
      <Tab eventKey="references" title="Impactos / Referências">
        <References areaAcronym={areaAcronym} areaLabel={areaLabel} />
      </Tab>
      {showHistory && (
        <Tab eventKey="history" title="Histórico">
          <History areaAcronym={areaAcronym} areaLabel={areaLabel} />
        </Tab>
      )}
    </Tabs>
  );
}

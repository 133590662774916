import { faChartLine, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

interface ViewTogglerProps {
  showGraph: boolean,
  showTable: boolean,
  toggleGraph: () => void,
  toggleTable: () => void,
}

export default function ViewToggler({
  showGraph, showTable, toggleGraph, toggleTable,
}: ViewTogglerProps) {
  const activeStyle = { backgroundColor: '#26548A', borderWidth: 0 };
  const inactiveStyle = { backgroundColor: '#D9D9D9', borderWidth: 0 };

  return (
    <div className="d-flex">
      <Button
        className="me-2 btn"
        style={showGraph ? activeStyle : inactiveStyle}
        onClick={toggleGraph}
      >
        <FontAwesomeIcon
          icon={faChartLine}
          fontSize="25px"
        />
      </Button>
      <Button
        className="btn btn-primary"
        onClick={toggleTable}
        style={showTable ? activeStyle : inactiveStyle}
      >
        <FontAwesomeIcon
          icon={faTable}
          fontSize="25px"
        />
      </Button>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';

export default function usePredictionParametersSelection({
  area,
  setPeriodOptions,
  setImage,
  triggerPeriods,
  triggerPrediction,
}: {
  area: string,
  setPeriodOptions: React.Dispatch<React.SetStateAction<string[]>>,
  setImage: React.Dispatch<React.SetStateAction<string>>,
  triggerPeriods: LazyQueryTrigger<any>,
  triggerPrediction: LazyQueryTrigger<any>,
}) {
  const [previousArea, setPreviousArea] = useState('');
  const [model, setModel] = useState('');
  const [period, setPeriod] = useState('');

  const onModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setModel(value);
    if (area && value) {
      triggerPeriods({ area, model: value });
    }
  };
  const onPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setPeriod(value);
    if (area && model && value) {
      triggerPrediction({ area, model, period: value });
    }
  };

  useEffect(() => {
    if (previousArea !== area) {
      if (previousArea) {
        setModel('');
        setPeriod('');
        setPeriodOptions([]);
        setImage('');
      }
      setPreviousArea(area);
    }
  }, [area, previousArea, setImage, setPeriodOptions]);

  useEffect(() => {
    setPeriod('');
    setImage('');
  }, [model, setImage]);

  return {
    model,
    period,
    onModelChange,
    onPeriodChange,
  };
}

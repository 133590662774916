import React from 'react';
import { Button, Card, FormSelect } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useOptionSelection from 'hooks/useOptionSelection';
import useDatedImageDownload from 'hooks/useDatedImageDownload';
import useMJOStatus from 'hooks/Meteorology/Weather/Area/Status/MJO/useMJOStatus';
import CentralizedSpinner from 'components/CentralizedSpinner';
import EnlargeableImage from 'components/Meteorology/Fields/SynopticCharts/SynopticChart/Map/EnlargeableImage';

export default function DiagramPanel({ models }: { models: string[] }) {
  const { option: model, handleOptionChange } = useOptionSelection();
  const { statusImageBase64, statusImageLoading } = useMJOStatus(model);
  const {
    onDownloadClick,
    downloadDisabled,
  } = useDatedImageDownload(statusImageBase64, statusImageLoading, `mjo_status_${model}`);

  return (
    <Card className="shadow-sm forecast-instruction-card h-100">
      <Card.Header>
        DIAGRAMA DE FASES
      </Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-between mt-3 me-3 align-items-center">
          <FormSelect
            id="modelSelect"
            className="me-3"
            value={model}
            onChange={handleOptionChange}
          >
            <option value="">Modelo</option>
            {models.map((option) => (
              <option value={option} key={option}>{option}</option>
            ))}
          </FormSelect>
          <Button
            id="diagramImageDownloadButton"
            size="sm"
            className="icon-button weather-download-button"
            onClick={onDownloadClick}
            disabled={downloadDisabled}
          >
            <FontAwesomeIcon icon={faDownload as IconProp} />
          </Button>
        </div>
        {statusImageLoading && <CentralizedSpinner />}
        <EnlargeableImage src={`data:image/png;base64,${statusImageBase64}`} className="mt-3" />
      </Card.Body>
    </Card>
  );
}

import { useListCompaniesQuery } from 'services/api/companies';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import { ResponseError } from 'services/api/base';

export default function useListCompanies() {
  const { data, isLoading, error } = useListCompaniesQuery();
  useHandleRequestErrors(error as ResponseError);

  return { companies: data, isLoading };
}

import { GetHistoryResponse, WeatherHistory } from 'types/Meteorology/Weather/Area/getHistoryResponse';
import HistoryRange from 'types/Meteorology/Weather/Area/historyRange';
import months from 'helper/Shared/months';

export const historyRanges = {
  all: 'Tudo',
  '30years': '30 Anos',
  '10years': '10 Anos',
  '5years': '5 Anos',
  '1year': '1 Ano',
  '6months': '6 Meses',
};

const selectionMonths = {
  '6months': 6,
  '1year': 12,
  '5years': 5 * 12,
  '10years': 10 * 12,
  '30years': 30 * 12,
};

export const transformGetHistoryResponse = (
  response: GetHistoryResponse,
): WeatherHistory => {
  if ('amplitude' in response.data && 'fase' in response.data) {
    return {
      amplitude: {
        categoryData: response.data.amplitude.categoryData,
        valueData: response.data.amplitude.valueData,
      },
      fase: {
        categoryData: response.data.fase.categoryData,
        valueData: response.data.fase.valueData,
      },
    };
  }
  return {
    categoryData: response.data.categoryData,
    valueData: response.data.valueData,
  };
};

export function dateRange(
  firstDate: string,
  lastDate: string,
) {
  const formattedDate = (date: string) => {
    const [year, month, day] = date.split('-');
    return `${day ? `${day} ` : ''}${months.short[month]}, ${year}`;
  };

  return {
    first: formattedDate(firstDate),
    last: formattedDate(lastDate),
  };
}

export function filterHistoryData(
  referenceDate: Date,
  selection: HistoryRange,
  valueData: number[],
  categoryData: string[],
) {
  if (selection === 'all') {
    return { filteredCategories: categoryData, filteredValues: valueData };
  }
  const newValues: number[] = [];
  const newCategories: string[] = [];
  const todayMonths = referenceDate.getFullYear() * 12 + referenceDate.getMonth();
  categoryData.forEach((category, index) => {
    const data = category.split('-');
    const dataYear = Number.parseInt(data[0], 10);
    const dataMonth = Number.parseInt(data[1], 10) - 1;
    const dataMonths = dataYear * 12 + dataMonth;
    if (todayMonths - dataMonths <= selectionMonths[selection]) {
      newValues.push(valueData[index]);
      newCategories.push(category);
    }
  });
  return { filteredCategories: newCategories, filteredValues: newValues };
}

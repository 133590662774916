import { useState, useEffect } from 'react';

// We receive times in YYYY-M/YYYY-MM/YYYY-MM-D/YYYY-MM-DD format,
// we need to convert them to YYYY-MM-DD.
export default function useParseHistoryCategories(categoryData: string[]) {
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    const newCategories: string[] = [];
    categoryData.forEach((data) => {
      const parsing = data.split('-');
      if (parsing[2] === undefined) {
        parsing.push('1');
      }
      parsing[1] = parsing[1].padStart(2, '0');
      parsing[2] = parsing[2].padStart(2, '0');
      newCategories.push(parsing.join('-'));
    });
    setCategories([...newCategories]);
  }, [categoryData]);

  return { categories };
}

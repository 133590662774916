import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectFluxAutomaticControlPanelClassicAcomphDate,
  selectFluxAutomaticControlPanelClassicForecastDate,
  selectFluxAutomaticControlPanelClassicModel,
  selectFluxAutomaticControlPanelClassicRevision,
  setClassicRevision,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { useLazyGetClassicRevisionsQuery } from 'services/api/products/flux';
import { ResponseError } from 'services/api/base';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useClassicRevisionList() {
  const dispatch = useDispatch();
  const [trigger, { data, error }] = useLazyGetClassicRevisionsQuery();
  const revisionOptions = useMemo(() => data || [], [data]);
  const showRevisionList = revisionOptions.length > 0;

  const acomphDateLabel = useSelector(selectFluxAutomaticControlPanelClassicAcomphDate);
  const forecastDateLabel = useSelector(selectFluxAutomaticControlPanelClassicForecastDate);
  const model = useSelector(selectFluxAutomaticControlPanelClassicModel);
  const selectedRevision = useSelector(selectFluxAutomaticControlPanelClassicRevision);

  const setSelectedRevision = (newRevision: string) => {
    dispatch(setClassicRevision(newRevision));
  };

  useHandleRequestErrors(error as ResponseError);

  useEffect(() => {
    if (acomphDateLabel && forecastDateLabel && model) {
      trigger({ acomphDateLabel, forecastDateLabel, model });
    }
  }, [acomphDateLabel, forecastDateLabel, model, trigger]);

  useEffect(() => {
    const revisionAvailable = revisionOptions.includes(selectedRevision);
    if (!revisionAvailable) dispatch(setClassicRevision(''));
  }, [selectedRevision, revisionOptions, dispatch]);

  return {
    showRevisionList,
    revisionOptions,
    selectedRevision,
    setSelectedRevision,
  };
}

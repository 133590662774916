import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Alert, Button, Col, Modal, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { selectFluxAutomaticControlPanelMltOption, selectFluxAutomaticControlPanelSpatialSubdivision } from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { RevisionDataPerScenario, buildSubdivisionParam } from 'helper/flux/automatic';
import useFluxAutomaticZipDownload from 'utils/hooks/flux/automatic/useFluxAutomaticZipDownload';
import CentralizedSpinner from 'components/CentralizedSpinner';
import './index.scss';
import FluxAutomaticGeneralTable from '../../GeneralTable';

export interface RevisionModal {
  isOpen: boolean
  modelName: string
  period: string
  onClose(): void
  revision: string | undefined
  errorMessage: string | undefined
  data: RevisionDataPerScenario | undefined,
  toFirst: () => void,
  toPrevious: () => void,
  toNext: () => void,
  toLast: () => void,
  isFirstPeriod: boolean,
  isLastPeriod: boolean,
  isFetchingScenarios: boolean,
}

export default function RevisionSubTableModal({
  isOpen,
  modelName,
  period,
  onClose,
  data,
  errorMessage,
  revision,
  toFirst,
  toPrevious,
  toNext,
  toLast,
  isFirstPeriod,
  isLastPeriod,
  isFetchingScenarios,
}: RevisionModal) {
  const unidade = useSelector(selectFluxAutomaticControlPanelMltOption);
  const subdivision = useSelector(selectFluxAutomaticControlPanelSpatialSubdivision);
  const spatialSubdivisionParam = useMemo(() => buildSubdivisionParam(subdivision), [subdivision]);

  const { DownloadZipButton, DownloadConfirmationDialog } = useFluxAutomaticZipDownload({
    models: [modelName],
    review: revision,
    unidade,
    forecastDate: period,
    type: 'revision',
    pastSimulationNames: [],
    spatialSubdivisionParam,
  });

  const emptyData = {
    colLabels: [],
    rowLabels: [],
    values: [],
  };
  const fallbackObject = {
    bacias: emptyData,
    postos: emptyData,
    sistema: emptyData,
    subsistemas: emptyData,
    reservatorios: emptyData,
  };
  const selectedModelData = data !== undefined && modelName in data
    ? data[modelName]
    : fallbackObject;

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      dialogClassName="default-modal modal-dialog-scrollable flux-sub-table"
    >
      <Modal.Header>
        <Modal.Title>
          <Row>
            <Col className="header">
              <span className="model-name">{modelName}</span>
              <span className="revision">{revision}</span>
              <span className="period">{period}</span>
            </Col>
            <Col md={1}>
              <DownloadZipButton />
              <DownloadConfirmationDialog />
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isFetchingScenarios && (
          <div className="flux-sub-table-spinner-container">
            <CentralizedSpinner />
          </div>
        )}
        {!isFetchingScenarios && (
          <div className="d-flex flex-row">
            <div className="flux-sub-table-arrow-container">
              <Button
                variant="link"
                size="lg"
                disabled={isFirstPeriod}
                onClick={toFirst}
                className="flux-sub-table-arrow"
              >
                <FontAwesomeIcon className="" icon={faAngleDoubleLeft} />
              </Button>
            </div>
            <div className="flux-sub-table-arrow-container">
              <Button
                variant="link"
                size="lg"
                disabled={isFirstPeriod}
                onClick={toPrevious}
                className="flux-sub-table-arrow"
              >
                <FontAwesomeIcon className="" icon={faAngleLeft} />
              </Button>
            </div>
            <div className="flex-grow-1 mx-3">
              { errorMessage
                ? (
                  <Alert variant="light">
                    {errorMessage}
                  </Alert>
                )
                : Object.keys(selectedModelData).map((system) => (
                  <FluxAutomaticGeneralTable
                    data={selectedModelData[system]}
                    lastColAsPercent
                    tableLabel={system}
                    key={system}
                  />
                ))}
            </div>
            <div className="flux-sub-table-arrow-container">
              <Button
                variant="link"
                size="lg"
                disabled={isLastPeriod}
                onClick={toNext}
                className="flux-sub-table-arrow"
              >
                <FontAwesomeIcon className="" icon={faAngleRight} />
              </Button>
            </div>
            <div className="flux-sub-table-arrow-container">
              <Button
                variant="link"
                size="lg"
                disabled={isLastPeriod}
                onClick={toLast}
                className="flux-sub-table-arrow"
              >
                <FontAwesomeIcon className="" icon={faAngleDoubleRight} />
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

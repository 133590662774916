import { ResponseError } from 'services/api/base';
import { useGetInstructionTabsQuery } from 'services/api/products/flux';
import { useHandleRequestErrors } from 'utils/hooks';

export default function useInstructionTabs() {
  const { data, error, isLoading } = useGetInstructionTabsQuery();

  useHandleRequestErrors(error as ResponseError);

  return {
    instructionTabs: data,
    isLoading,
  };
}

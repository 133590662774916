import { ResponseError } from 'services/api/base';
import { CustomAlert } from 'components/AlertsCollection';
import { useNavigate } from 'react-router';
import { handleApiError } from 'helper/error';

export default function useDisplayErrorMessages(
  error: ResponseError | undefined,
): CustomAlert[] {
  const navigate = useNavigate();
  const isAlertable = error && 'description' in error;

  if (!error) return [];

  if (isAlertable) {
    return [{ title: error?.data ?? '', variant: 'danger', message: error?.description ?? '' }];
  }

  handleApiError(navigate, error);
  return [];
}

import React from 'react';
import { Dropdown } from 'react-bootstrap';

import useClassicRevisionList from 'hooks/Flux/Automatic/Classic/useClassicRevisionList';

export default function ClassicRevisionSelector() {
  const {
    showRevisionList,
    revisionOptions,
    selectedRevision,
    setSelectedRevision,
  } = useClassicRevisionList();

  if (!showRevisionList) {
    return <div />;
  }

  return (
    <Dropdown>
      <Dropdown.Toggle className="flux-automatic-filter-selector-toggle">
        {selectedRevision || 'Selecione uma revisão'}
      </Dropdown.Toggle>
      <Dropdown.Menu className="flux-automatic-filter-selector-menu">
        {revisionOptions.map((option) => (
          <Dropdown.Item
            key={option}
            onClick={() => setSelectedRevision(option)}
            className={`flux-automatic-filter-selector-item ${option === selectedRevision ? 'active' : ''}`}
          >
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

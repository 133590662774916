import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MeteorologyFieldsControlPanelState {
  region: string | undefined
  models: string[] | undefined
  forecastUnixDate: number | undefined
  period: string | undefined
  selectedOffset: number,
  anomaly: boolean,
  used: boolean,
}

const initialState: MeteorologyFieldsControlPanelState = {
  region: undefined,
  models: [],
  forecastUnixDate: undefined,
  period: undefined,
  selectedOffset: 0,
  anomaly: false,
  used: false,
};

export const meteorologyFieldsControlPanelSlice = createSlice({
  name: 'meteorologyFieldsControlPanel',
  initialState,
  reducers: {
    setRegion: (state, action: PayloadAction<string>) => {
      state.region = action.payload;
    },
    setForecastUnixDate: (state, action: PayloadAction<number>) => {
      state.forecastUnixDate = action.payload;
    },
    setPeriod: (state, action: PayloadAction<string>) => {
      state.period = action.payload;
    },
    setModels: (state, action: PayloadAction<string[]>) => {
      state.models = action.payload;
    },
    setDateOffset: (state, action: PayloadAction<number>) => {
      state.selectedOffset = action.payload;
    },
    setAnomaly: (state, action: PayloadAction<boolean>) => {
      state.anomaly = action.payload;
    },
    setUsed: (state, action: PayloadAction<boolean>) => {
      state.used = action.payload;
    },
    reset: (state) => {
      state.forecastUnixDate = undefined;
      state.region = undefined;
      state.models = [];
      state.period = undefined;
      state.selectedOffset = 0;
      state.anomaly = false;
      state.used = false;
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectMFControlPanelForecastUnixDate = (state: any) => (
  state.meteorologyFieldsControlPanel.forecastUnixDate
);
export const selectMFControlPanelPeriod = (state: any) => (
  state.meteorologyFieldsControlPanel.period
);
export const selectMFControlPanelModels = (state: any) => (
  state.meteorologyFieldsControlPanel.models
);
export const selectMFControlPanelRegion = (state: any) => (
  state.meteorologyFieldsControlPanel.region
);
export const selectMFControlPanelDateOffset = (state: any) => (
  state.meteorologyFieldsControlPanel.selectedOffset
);
export const selectMFControlPanelAnomaly = (state: any) => (
  state.meteorologyFieldsControlPanel.anomaly
);
export const selectMFControlPanelUsed = (state: any) => (
  state.meteorologyFieldsControlPanel.used
);
/* eslint-enable @typescript-eslint/no-explicit-any */
export const {
  setPeriod,
  setModels,
  setRegion,
  setDateOffset,
  setForecastUnixDate,
  setAnomaly,
  setUsed,
  reset,
} = meteorologyFieldsControlPanelSlice.actions;

export default meteorologyFieldsControlPanelSlice.reducer;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Chart } from 'helper/products/synopticCharts';
import slugify from 'helper/slugify';
import { downloadZipFromUrls } from 'helper/downloadFile';
import {
  selectChart, selectDate, selectGifs, selectGifsAreLoading, selectImages, selectImagesAreLoading,
} from 'redux/reducers/synopticChartsReducer';

export default function DownloadButton() {
  const gifData = useSelector(selectGifs);
  const gifsAreLoading = useSelector(selectGifsAreLoading);

  const imageData = useSelector(selectImages);
  const imagesAreLoading = useSelector(selectImagesAreLoading);

  const chart: Chart = useSelector(selectChart);
  const forecastDate = useSelector(selectDate);
  const [isDownloading, setIsDownloading] = useState(false);

  const isLoading = gifsAreLoading || imagesAreLoading || isDownloading;

  const gifsAvailable = gifData?.length && gifData.length > 0;
  const imagesAvailable = imageData?.length && imageData.length > 0;
  const dataAvailable = gifsAvailable || imagesAvailable;
  const disabled = gifsAreLoading || imagesAreLoading || !dataAvailable;

  const downloadData = async () => {
    if (!dataAvailable) return;

    setIsDownloading(true);

    const zipName = slugify(`${forecastDate}} ${chart.campo}`);
    const gifFiles = gifData?.map((url, i) => ({
      url,
      filename: `Pêntada ${i + 1}.gif`,
    })) || [];
    const imageFiles = imageData?.map(({ date, url }) => ({
      url,
      filename: `${date}.png`,
    })) || [];

    await downloadZipFromUrls([...gifFiles, ...imageFiles], zipName);
    setIsDownloading(false);
  };

  return (
    <Button disabled={disabled} onClick={downloadData}>
      {!isLoading && <FontAwesomeIcon icon={faDownload} />}
      {isLoading && <Spinner className="spinner-border-sm" role="status" aria-hidden />}
    </Button>
  );
}

import { useEffect, useState } from 'react';
import { ResponseError } from 'services/api/base';
import { useLazyGetAaoNaoPredictionQuery, useLazyGetAaoNaoStatusQuery } from 'services/api/Meteorology/Weather/Area/Status/AAONAO';
import { useHandleRequestErrors } from 'utils/hooks';

export default function useAaoNaoSelection(area: 'aao' | 'nao') {
  const [previousArea, setPreviousArea] = useState('');
  const [model, setModel] = useState('');
  const [period, setPeriod] = useState('');
  const [statusImage, setStatusImage] = useState('');
  const [predictionImage, setPredictionImage] = useState('');
  const [triggerStatus, {
    data: statusData, error: statusError, isFetching: fetchingStatus,
  }] = useLazyGetAaoNaoStatusQuery();
  const [triggerPrediction, {
    data: predictionData, error: predictionError, isFetching: fetchingPrediction,
  }] = useLazyGetAaoNaoPredictionQuery();

  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setModel(value);
    if (value) {
      triggerPrediction({ area, model: value });
    }
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setPeriod(value);
    if (value) {
      triggerStatus({ area, period: value });
    }
  };

  useEffect(() => {
    if (area !== previousArea) {
      if (previousArea) {
        setModel('');
        setPeriod('');
        setPredictionImage('');
        setStatusImage('');
      }
      setPreviousArea(area);
    }
  }, [area, previousArea]);

  useEffect(() => {
    if (statusData && !fetchingStatus) {
      setStatusImage(statusData.statusImageBase64);
    }
  }, [statusData, fetchingStatus]);

  useEffect(() => {
    if (predictionData && !fetchingPrediction) {
      setPredictionImage(predictionData.predictionImageBase64);
    }
  }, [predictionData, fetchingPrediction]);

  useHandleRequestErrors(statusError as ResponseError);
  useHandleRequestErrors(predictionError as ResponseError);

  return {
    model,
    handleModelChange,
    period,
    handlePeriodChange,
    statusImageBase64: statusImage,
    statusImageLoading: fetchingStatus,
    predictionImageBase64: predictionImage,
    predictionImageLoading: fetchingPrediction,
  };
}

import useImageDownload from 'hooks/useImageDownload';

export default function useStatusImageDownload(
  imageBase64: string,
  imageLoading: boolean,
  area: string,
) {
  const downloadDisabled = imageLoading || !imageBase64;
  const date = (new Date()).toLocaleDateString('pt-BR').replace(/\/|:|,|\s/g, '_');
  const filename = `status_${area}_${date}.png`;
  const { onDownloadClick } = useImageDownload(imageBase64, downloadDisabled, filename);

  return {
    onStatusDownloadClick: onDownloadClick,
    downloadDisabled,
  };
}

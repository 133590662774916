import baseApi from 'services/api/base';
import { WeatherAreaReferences } from 'types/Meteorology/Weather/Area/getImpactReferenceResponse';
import { selectMeteorologyKey } from 'redux/reducers/productsReducer';
import { store } from 'redux/store';
import transformImpactReferenceResponse from 'helper/Meteorology/Weather/Area/References';
import ImpactReferenceParams from 'types/Meteorology/Weather/Area/getImpactReferenceParams';

export const weatherAreaImpactReferenceApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    impactReference: build.query<WeatherAreaReferences, ImpactReferenceParams>({
      transformResponse: transformImpactReferenceResponse,
      query: ({ area }) => {
        const productKey = selectMeteorologyKey(store.getState());
        return {
          url: `produtos/clima_exclusivo/impact-reference/?product_key=${productKey}`,
          method: 'POST',
          body: { area },
        };
      },
    }),
  }),
});

export const {
  useImpactReferenceQuery,
} = weatherAreaImpactReferenceApi;

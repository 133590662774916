import { useEffect, useState } from 'react';
import { ResponseError } from 'services/api/base';
import { useLazyGetOptionPeriodsQuery } from 'services/api/Meteorology/Weather/Area/Status';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function usePeriodOptions() {
  const [periodOptions, setPeriodOptions] = useState<string[]>([]);
  const [trigger, {
    data, error, isFetching,
  }] = useLazyGetOptionPeriodsQuery();

  useHandleRequestErrors(error as ResponseError);

  useEffect(() => {
    if (data && !isFetching) {
      setPeriodOptions(data);
    }
  }, [data, isFetching]);

  return {
    periodOptions,
    periodOptionsLoading: isFetching,
    setPeriodOptions,
    triggerPeriods: trigger,
  };
}

import { store } from 'redux/store';
import { selectMeteorologyKey } from 'redux/reducers/productsReducer';
import baseApi from 'services/api/base';
import { transformGetTropicoOptionsResponse, transformGetTropicoStatusResponse } from 'helper/Meteorology/Weather/Area/Status/Tropico';
import { TropicoOptions } from 'types/Meteorology/Weather/Area/Status/Tropico/getTropicoOptionsResponse';
import { GetTropicoStatusParams } from 'types/Meteorology/Weather/Area/Status/Tropico/getTropicoStatusParams';
import { TropicoStatusImages } from 'types/Meteorology/Weather/Area/Status/Tropico/getTropicoStatusResponse';

export const weatherAreaStatusTropicoApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTropicoOptions: build.query<TropicoOptions, void>({
      transformResponse: transformGetTropicoOptionsResponse,
      query: () => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/options-tropico/?product_key=${productKey}`,
          method: 'POST',
          body: { area: 'tropico' },
        };
      },
    }),
    getTropicoStatus: build.query<TropicoStatusImages, GetTropicoStatusParams>({
      transformResponse: transformGetTropicoStatusResponse,
      query: ({ model, wave }) => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/status-tropico/?product_key=${productKey}`,
          method: 'POST',
          body: { area: 'tropico', modelo: model, onda: wave },
        };
      },
    }),
  }),
});

export const {
  useGetTropicoOptionsQuery,
  useLazyGetTropicoOptionsQuery,
  useGetTropicoStatusQuery,
  useLazyGetTropicoStatusQuery,
} = weatherAreaStatusTropicoApi;

import CentralizedSpinner from 'components/CentralizedSpinner';
import useInstructionPdf from 'hooks/Flux/About/useInstructionPdf';
import React from 'react';
import { InstructionTab } from 'types/Flux/About/getInstructionTabsResponse';

export default function AboutSection({ tab, activeKey }: {
  tab: InstructionTab,
  activeKey: string | null,
}) {
  const { base64Pdf, loading } = useInstructionPdf(tab.id, tab.id === Number(activeKey));

  return (
    <div className="p-4">
      {loading && <CentralizedSpinner />}
      {base64Pdf && (
        <object
          title={`${tab.title} PDF`}
          data={`data:application/pdf;base64,${base64Pdf}`}
          className="flux-about-pdf"
        />
      )}
    </div>
  );
}

import { ResponseError } from 'services/api/base';
import { useGetPlumaQuery } from 'services/api/Meteorology/Weather/Area/Status';
import { useHandleRequestErrors } from 'utils/hooks';

export default function usePluma(area: string) {
  const { data, error, isLoading } = useGetPlumaQuery({ area });

  useHandleRequestErrors(error as ResponseError);

  return { data, isLoading };
}

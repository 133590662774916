import { store } from 'redux/store';
import { selectMeteorologyKey } from 'redux/reducers/productsReducer';
import baseApi from 'services/api/base';
import { GetOptionPeriodsParams } from 'types/Meteorology/Weather/Area/Status/getOptionPeriodsParams';
import { StatusPeriods } from 'types/Meteorology/Weather/Area/Status/getOptionPeriodsResponse';
import {
  transformGetModelOptionsResponse,
  transformGetOptionPeriodsResponse,
  transformGetPlumaResponse,
  transformGetPredictionResponse,
  transformGetStatusResponse,
} from 'helper/Meteorology/Weather/Area/Status';
import { StatusImage } from 'types/Meteorology/Weather/Area/Status/getStatusResponse';
import { GetStatusParams } from 'types/Meteorology/Weather/Area/Status/getStatusParams';
import { PredictionImage } from 'types/Meteorology/Weather/Area/Status/getPredictionResponse';
import { GetPredictionParams } from 'types/Meteorology/Weather/Area/Status/getPredictionParams';
import { GetPlumaParams } from 'types/Meteorology/Weather/Area/Status/getPlumaParams';
import { IndicesPrediction } from 'types/Meteorology/Weather/Area/Status/getPlumaResponse';

export const weatherAreaStatusApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getModelOptions: build.query<string[], void>({
      transformResponse: transformGetModelOptionsResponse,
      query: () => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/status-prediction-options-modelos/?product_key=${productKey}`,
          method: 'GET',
        };
      },
    }),
    getOptionPeriods: build.query<StatusPeriods, GetOptionPeriodsParams>({
      transformResponse: transformGetOptionPeriodsResponse,
      query: ({ model, area }) => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/status-prediction-options-periods/?product_key=${productKey}`,
          method: 'POST',
          body: {
            modelo: model,
            area,
          },
        };
      },
    }),
    getAreaStatus: build.query<StatusImage, GetStatusParams>({
      transformResponse: transformGetStatusResponse,
      query: ({ area, period }) => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/status/?product_key=${productKey}`,
          method: 'POST',
          body: { area, periodo: period },
        };
      },
    }),
    getAreaPrediction: build.query<PredictionImage, GetPredictionParams>({
      transformResponse: transformGetPredictionResponse,
      query: ({ model, area, period }) => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/prediction/?product_key=${productKey}`,
          method: 'POST',
          body: {
            modelo: model,
            area,
            tempo: period,
          },
        };
      },
    }),
    getPluma: build.query<IndicesPrediction, GetPlumaParams>({
      transformResponse: transformGetPlumaResponse,
      query: ({ area }) => {
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/pluma/?product_key=${productKey}`,
          method: 'POST',
          body: {
            area,
          },
        };
      },
    }),
  }),
});

export const {
  useGetModelOptionsQuery,
  useGetOptionPeriodsQuery,
  useLazyGetOptionPeriodsQuery,
  useGetAreaStatusQuery,
  useLazyGetAreaStatusQuery,
  useGetAreaPredictionQuery,
  useLazyGetAreaPredictionQuery,
  useGetPlumaQuery,
} = weatherAreaStatusApi;

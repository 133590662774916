import React, { useState } from 'react';

import { Navigate, useParams } from 'react-router-dom';
import {
  Col, Row, Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

import DatePicker from 'components/DatePicker';
import SearchBar from 'components/SearchBar';
import Tabs from 'components/Videos/Tabs';
import VideosList from 'components/Videos/List';
import {
  VideoProduct,
  videoProductToPageTitle,
  videoProductToPath,
  videoProductToPermission,
  videoProductToRoute,
} from 'helper/videos';
import { useGetVideoCategoriesQuery } from 'services/api/videos';
import { onDatePickerChange } from 'helper/controlledField';
import { GRAY1 } from 'constants/colors';
import './index.scss';

export default function VideoList({
  product,
  viewOnly,
}: {
  product: VideoProduct;
  viewOnly: boolean;
}) {
  const productPath = videoProductToPath[product];
  const productRoute = videoProductToRoute[product];
  const pageTitle = videoProductToPageTitle[product];
  const permissionType = videoProductToPermission[product];

  const { id: baseId } = useParams();
  const id = baseId && parseInt(baseId, 10);

  const { data } = useGetVideoCategoriesQuery({ productPath, permissionType });
  const categories = data?.data;

  const [searchBarText, setSearchBarText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const uploadDate = selectedDate?.toLocaleDateString('pt-BR') || '';

  if (!categories) {
    return <div className="text-center"><Spinner /></div>;
  }

  const validId = typeof id === 'number' && categories.map((c) => c.id).includes(id);

  if (!validId) {
    return <Navigate to={`${productRoute}/categories/${categories[0].id}`} />;
  }

  return (
    <>
      <Row>
        <Col md={4}>
          <h1 className="mb-3">{pageTitle}</h1>
        </Col>
        <Col md={5}>
          <SearchBar
            text={searchBarText}
            setText={setSearchBarText}
            setSearchQuery={setSearchQuery}
          />
        </Col>
        <Col md={3}>
          <div className="form-group video-datepicker">
            <span className="icon">
              <FontAwesomeIcon icon={faCalendar} color={GRAY1} />
            </span>
            <DatePicker
              className="form-control"
              isClearable
              placeholderText="Selecione uma data"
              selected={selectedDate}
              onChange={onDatePickerChange(setSelectedDate)}
            />
          </div>
        </Col>
      </Row>
      <div style={{ backgroundColor: '#f4f4f4' }}>
        <Tabs categories={categories} product={product} />
        <VideosList
          key={`${id}-${searchQuery}-${uploadDate}`}
          categoryId={id}
          searchQuery={searchQuery}
          uploadDate={uploadDate}
          product={product}
          viewOnly={viewOnly}
        />
      </div>
    </>
  );
}

import { useState } from 'react';

export default function useOptionSelection() {
  const [option, setOption] = useState('');
  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setOption(value);
  };

  return {
    option,
    handleOptionChange,
  };
}

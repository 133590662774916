import React from 'react';
import {
  Col, Row, Card, FormSelect, Button,
} from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAaoNaoOptions from 'hooks/Meteorology/Weather/Area/Status/AAONAO/useAaoNaoOptions';
import useStatusImageDownload from 'hooks/Meteorology/Weather/Area/Status/useStatusImageDownload';
import usePredictionImageDownload from 'hooks/Meteorology/Weather/Area/Status/usePredictionImageDownload';
import useAaoNaoSelection from 'hooks/Meteorology/Weather/Area/Status/AAONAO/useAaoNaoSelection';
import CentralizedSpinner from 'components/CentralizedSpinner';
import EnlargeableImage from 'components/Meteorology/Fields/SynopticCharts/SynopticChart/Map/EnlargeableImage';

export default function AAONAO({ area }: { area: 'aao' | 'nao' }) {
  const { data, isLoading: optionsLoading } = useAaoNaoOptions(area);
  const {
    model,
    handleModelChange,
    period,
    handlePeriodChange,
    statusImageBase64,
    statusImageLoading,
    predictionImageBase64,
    predictionImageLoading,
  } = useAaoNaoSelection(area);
  const {
    onStatusDownloadClick,
    downloadDisabled: statusDownloadDisabled,
  } = useStatusImageDownload(statusImageBase64, statusImageLoading, area);
  const {
    onPredictionDownloadClick,
    downloadDisabled: predictionDownloadDisabled,
  } = usePredictionImageDownload(
    predictionImageBase64,
    predictionImageLoading,
    area,
    model,
    period,
  );

  return (
    <Row className="flex-fill p-5 h-100">
      <Col xl={6}>
        <Card className="shadow-sm forecast-instruction-card h-100">
          <Card.Header>
            OBSERVADO
          </Card.Header>
          <Card.Body>
            {optionsLoading ? (
              <CentralizedSpinner />
            ) : (
              <>
                <div className="d-lg-flex mt-3 mx-3 align-items-center">
                  <FormSelect
                    id="periodSelect"
                    className="ms-lg-2"
                    value={period}
                    onChange={handlePeriodChange}
                  >
                    <option value="">Período</option>
                    {Object.entries(data?.periods || {}).map(([key, value]) => (
                      <option value={key} key={key}>{value}</option>
                    ))}
                  </FormSelect>
                  <Button
                    id="statusImageDownloadButton"
                    size="sm"
                    className="icon-button weather-download-button ms-lg-3"
                    onClick={onStatusDownloadClick}
                    disabled={statusDownloadDisabled}
                  >
                    <FontAwesomeIcon icon={faDownload as IconProp} />
                  </Button>
                </div>
                {statusImageLoading && <CentralizedSpinner />}
                {!statusImageLoading && statusImageBase64 && (
                  <EnlargeableImage
                    src={`data:image/png;base64,${statusImageBase64}`}
                    className="mt-3"
                  />
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
      <Col xl={6} className="mt-3 mt-xl-0">
        <Card className="shadow-sm forecast-instruction-card h-100">
          <Card.Header>
            FONTE
          </Card.Header>
          <Card.Body>
            {optionsLoading ? (
              <CentralizedSpinner />
            ) : (
              <>
                <div className="d-lg-flex mt-3 mx-3 align-items-center">
                  <FormSelect
                    id="modelSelect"
                    className="me-lg-2"
                    value={model}
                    onChange={handleModelChange}
                  >
                    <option value="">Modelo</option>
                    {data?.models.map((option) => (
                      <option value={option} key={option}>{option}</option>
                    ))}
                  </FormSelect>
                  <Button
                    id="previewImageDownloadButton"
                    size="sm"
                    className="icon-button weather-download-button ms-lg-3"
                    onClick={onPredictionDownloadClick}
                    disabled={predictionDownloadDisabled}
                  >
                    <FontAwesomeIcon icon={faDownload as IconProp} />
                  </Button>
                </div>
                {predictionImageLoading && <CentralizedSpinner />}
                {!predictionImageLoading && predictionImageBase64 && (
                  <EnlargeableImage
                    src={`data:image/png;base64,${predictionImageBase64}`}
                    className="mt-3"
                  />
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

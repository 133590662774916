import React, { useEffect, useState } from 'react';
import {
  Row, Col, Form, Spinner, Button,
} from 'react-bootstrap';
import useDateRange from 'utils/hooks/useDateRange';
import { useContractData, useFromUnixTime } from 'utils/hooks';
import useCurrencyTextInput from 'hooks/Admin/Contracts/FormFields/useCurrencyTextInput';
import DatePicker from 'components/DatePicker';
import ReadjustmentRate from './ReadjustmentRate';
import Status from './Status';
import Products from './Products';

export default function ContractFormFields({ contractId }: { contractId: number }) {
  const {
    contractData,
    isContractLoading,
  } = useContractData(contractId);

  const {
    startDate: termStartDate,
    endDate: termEndDate,
    onChangeStartDate: onChangeTermStartDate,
    onChangeEndDate: onChangeTermEndDate,
  } = useDateRange({
    defaultStartDate: useFromUnixTime(contractData?.termStartDate),
    defaultEndDate: useFromUnixTime(contractData?.termEndDate),
  });
  const [readjustmentIndex, setReadjustmentIndex] = useState(contractData?.readjustmentIndex);
  const {
    startDate: correctionStartDate,
    endDate: correctionEndDate,
    onChangeStartDate: onChangeCorrectionStartDate,
    onChangeEndDate: onChangeCorrectionEndDate,
  } = useDateRange({
    defaultStartDate: useFromUnixTime(contractData?.correctionStartDate),
    defaultEndDate: useFromUnixTime(contractData?.correctionEndDate),
  });
  const {
    display: contractValueDisplay,
    onChangeEvent: onContractValueChange,
  } = useCurrencyTextInput(contractData?.contractValue);
  const readjustmentDataInserted = Boolean(readjustmentIndex
    || correctionStartDate
    || correctionEndDate);
  const clearReadjustmentData = () => {
    setReadjustmentIndex(null);
    onChangeCorrectionStartDate(null);
    onChangeCorrectionEndDate(null);
  };
  const onChangeReadjustmentIndex = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    setReadjustmentIndex(value);
  };

  useEffect(() => {
    if (contractData) {
      setReadjustmentIndex(contractData.readjustmentIndex);
    }
  }, [contractData]);

  if (isContractLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Row className="my-4">
        <Form.Control defaultValue={contractId} name="contract_id" type="hidden" />

        <Col>
          <Form.Group controlId="companyContractValidityTerm">
            <Form.Label>Período de vigência do contrato</Form.Label>
            <Row>
              <Col>
                <DatePicker
                  selected={termStartDate}
                  onChange={onChangeTermStartDate}
                  className="form-control"
                  selectsStart
                  name="termStartDate"
                  startDate={termStartDate || undefined}
                  endDate={termEndDate || undefined}
                  maxDate={termEndDate || undefined}
                  required
                />
              </Col>
              <Col>
                <DatePicker
                  selected={termEndDate}
                  onChange={onChangeTermEndDate}
                  className="form-control"
                  selectsEnd
                  name="termEndDate"
                  startDate={termStartDate || undefined}
                  endDate={termEndDate || undefined}
                  minDate={termStartDate || undefined}
                  required
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <Form.Group controlId="companyContractValue">
            <Form.Label>Valor do contrato</Form.Label>
            <Form.Control
              required
              type="text"
              name="contractValue"
              placeholder="R$ 0,00"
              value={contractValueDisplay}
              onChange={onContractValueChange}
            />
            <Form.Control.Feedback type="invalid">
              O valor do contrato não pode ficar em branco
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="my-4 border-top pt-3">
        <Col>
          <Button
            size="sm"
            className="float-end p-1"
            onClick={clearReadjustmentData}
            disabled={!readjustmentDataInserted}
          >
            Limpar
          </Button>
          <ReadjustmentRate
            value={readjustmentIndex}
            onChange={onChangeReadjustmentIndex}
            required={readjustmentDataInserted}
          />
        </Col>
      </Row>
      <Row className="my-4 border-bottom pb-4">
        <Col>
          <Form.Group controlId="companyContractCorrectionTerm">
            <Form.Label>Período de correção monetária</Form.Label>
            <Row>
              <Col>
                <DatePicker
                  selected={correctionStartDate}
                  onChange={onChangeCorrectionStartDate}
                  className="form-control"
                  selectsStart
                  name="correctionStartDate"
                  startDate={correctionStartDate || undefined}
                  endDate={correctionEndDate || undefined}
                  maxDate={correctionEndDate || undefined}
                  required={readjustmentDataInserted}
                />
              </Col>
              <Col>
                <DatePicker
                  selected={correctionEndDate}
                  onChange={onChangeCorrectionEndDate}
                  className="form-control"
                  selectsEnd
                  name="correctionEndDate"
                  startDate={correctionStartDate || undefined}
                  endDate={correctionEndDate || undefined}
                  minDate={correctionStartDate || undefined}
                  required={readjustmentDataInserted}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <Form.Group controlId="companyProductCategory">
            <Form.Label>Pertence a</Form.Label>
            <br />
            <Form.Check
              inline
              name="productCategory"
              id="productCategoryMeteorology"
              label="Ampere Meteorologia"
              value={0}
              type="radio"
              required
              defaultChecked={contractData?.type === 0}
            />
            <Form.Check
              inline
              name="productCategory"
              label="Ampere Consultoria"
              value={1}
              type="radio"
              defaultChecked={contractData?.type === 1}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Status defaultValue={contractData?.statusId} />
      </Row>
      <Row className="my-4">
        <Col>
          <Products
            defaultProducts={contractData?.products}
            defaultStartDate={termStartDate}
            defaultEndDate={termEndDate}
          />
        </Col>
      </Row>
    </>
  );
}

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import useMJOOptions from 'hooks/Meteorology/Weather/Area/Status/MJO/useMJOOptions';
import CentralizedSpinner from 'components/CentralizedSpinner';
import DiagramPanel from './DiagramPanel';
import PredictionPanel from './PredictionPanel';

export default function MJO() {
  const { data, isLoading } = useMJOOptions();

  return (
    <>
      {isLoading && <CentralizedSpinner />}
      {!isLoading && (
        <Row className="flex-fill p-5 h-100">
          <Col xl={6}>
            <DiagramPanel models={data?.statusModels || []} />
          </Col>
          <Col xl={6} className="mt-3 mt-xl-0">
            <PredictionPanel
              models={data?.predictionModels || []}
              variables={data?.predictionVariables || []}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

import JSZip from 'jszip';

function downloadBlob(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = filename;
  downloadLink.click();
}

async function fileFromUrl(url: string, filename: string) {
  const blob = await fetch(url).then((response) => response.blob());
  return new File([blob], filename);
}

export default function downloadFile(b64File: string, filename: string) {
  const blobData = atob(b64File);
  const u8arr = new Uint8Array(blobData.length);
  for (let i = 0; i < blobData.length; i += 1) {
    u8arr[i] = blobData.charCodeAt(i);
  }
  const blob = new Blob([u8arr]);
  downloadBlob(blob, filename);
}

export async function downloadZip(
  files: { content: string, filename: string }[],
  zipFilename: string,
) {
  const zip = new JSZip();
  files.forEach((file) => {
    zip.file(file.filename, file.content, { base64: true });
  });

  return zip
    .generateAsync({ type: 'blob' })
    .then((blob) => {
      downloadBlob(blob, zipFilename);
    });
}

export async function downloadZipFromUrls(
  fileData: { url: string, filename: string }[],
  zipFilename: string,
) {
  const zip = new JSZip();
  const files = await Promise.all(
    fileData.map(({ url, filename }) => fileFromUrl(url, filename)),
  );
  files.forEach((file, i) => {
    zip.file(fileData[i].filename, file);
  });

  return zip
    .generateAsync({ type: 'blob' })
    .then((blob) => {
      downloadBlob(blob, zipFilename);
    });
}

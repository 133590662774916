import React from 'react';
import { Button } from 'react-bootstrap';
import { historyRanges } from 'helper/Meteorology/Weather/Area/History';
import HistoryRange from 'types/Meteorology/Weather/Area/historyRange';

export default function RangeSelector({
  selection,
  setSelection,
}: {
  selection: HistoryRange,
  setSelection: (selection: HistoryRange) => void,
}) {
  return (
    <div>
      {Object.keys(historyRanges).map((range) => (
        <Button
          key={range}
          size="sm"
          className="rounded me-3"
          variant={selection === range ? 'primary' : 'outline-primary'}
          onClick={() => setSelection(range as HistoryRange)}
        >
          {historyRanges[range]}
        </Button>
      ))}
    </div>
  );
}

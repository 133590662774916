import React from 'react';
import RegularStatusPrediction from './RegularStatusPrediction';
import AAONAO from './AAONAO';
import MJO from './MJO';
import Tropico from './Tropico';

export default function Status({ area }: { area: string }) {
  switch (area) {
    case 'aao':
      return <AAONAO area="aao" />;
    case 'mjo':
      return <MJO />;
    case 'nao':
      return <AAONAO area="nao" />;
    case 'tropicos':
      return <Tropico />;
    default:
      return <RegularStatusPrediction area={area} />;
  }
}

import { GetMJOOptionsResponse, MJOOptions } from 'types/Meteorology/Weather/Area/Status/MJO/getMJOOptionsResponse';
import { GetMJOPredictionResponse, MJOPrediction } from 'types/Meteorology/Weather/Area/Status/MJO/getMJOPredictionResponse';
import { GetMJOStatusResponse, MJOStatus } from 'types/Meteorology/Weather/Area/Status/MJO/getMJOStatusResponse';

export const transformGetMJOOptionsResponse = (
  response: GetMJOOptionsResponse,
): MJOOptions => ({
  statusModels: response.data.status_modelos,
  predictionModels: response.data.prediction.modelos,
  predictionVariables: response.data.prediction.variaveis,
});

export const transformGetMJOStatusResponse = (
  response: GetMJOStatusResponse,
): MJOStatus => ({
  imageBase64: response.data.status,
});

export const transformGetMJOPredictionResponse = (
  response: GetMJOPredictionResponse,
): MJOPrediction => ({
  imageBase64: response.data.prediction,
});

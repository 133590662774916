import React from 'react';
import {
  Accordion, Col, Container, Form, Row,
} from 'react-bootstrap';

export default function ForecastDateSelector({
  onChange,
  forecastDates,
}: {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  forecastDates: string[];
}) {
  return (
    <Accordion.Item eventKey="1" className="control-panel-item">
      <Accordion.Header>Data da Simulação</Accordion.Header>
      <Accordion.Body>
        <Container>
          {forecastDates.map((forecastDate) => (
            <Row key={forecastDate}>
              <Col>
                <Form.Check
                  name="forecastDate"
                  type="radio"
                  label={forecastDate}
                  onChange={onChange}
                  value={forecastDate}
                />
              </Col>
            </Row>
          ))}
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
}

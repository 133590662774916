import './index.scss';
import React from 'react';
import { Accordion } from 'react-bootstrap';
import useSaveFluxAutomaticPreference from 'utils/hooks/flux/automatic/useSaveFluxAutomaticPreference';
import { useSelector } from 'react-redux';
import {
  selectFluxAutomaticLayoutCurrentTab,
} from 'redux/reducers/fluxAutomaticLayoutReducer';
import AutomaticFrequentScenarios from './FrequentScenarios';
import AutomaticSavedPreferences from './SavedPreferences';
import AutomaticStudies from './AutomaticStudy';
import AutomaticControlPanelActions from './Actions';
import AcomphMLT from './AcomphMLT';
import PastSimulations from './PastSimulations';
import ClassicControlPanel from './ClassicControlPanel';
import ConfirmationModal
  from '../../../Meteorology/Fields/ControlPanel/NumericForecast/confirmationModal';
import ErrorModal from '../../../Meteorology/Fields/ControlPanel/NumericForecast/errorModal';
import SavePreferencesModal from './SavedPreferences/PreferenceSavingModal';

export default function ControlPanel() {
  const {
    showConfirmationModal,
    setShowConfirmationModal,
    showErrorModal,
    setShowErrorModal,
    error,
  } = useSaveFluxAutomaticPreference();
  const currentTab = useSelector(selectFluxAutomaticLayoutCurrentTab);

  return (
    <div>
      <ConfirmationModal
        open={showConfirmationModal}
        closing={() => { setShowConfirmationModal(false); }}
      />
      <ErrorModal
        open={showErrorModal}
        message={error?.data ?? ''}
        closing={() => { setShowErrorModal(false); }}
      />
      <SavePreferencesModal />
      <Accordion>
        {currentTab === 'classic' ? (
          <ClassicControlPanel />
        ) : (
          <>
            <AutomaticFrequentScenarios />
            <AutomaticSavedPreferences />
            <AutomaticStudies />
            {currentTab !== 'revision' && (
              <>
                <AcomphMLT />
                <PastSimulations />
              </>
            )}
          </>
        )}
      </Accordion>
      <AutomaticControlPanelActions />
    </div>
  );
}

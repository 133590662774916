import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, FormSelect } from 'react-bootstrap';
import useOverviewOptions from 'hooks/Meteorology/Weather/Overview/useOverviewOptions';
import useStatusPeriodSelection from 'hooks/Meteorology/Weather/Area/Status/useStatusPeriodSelection';
import useAreaStatusImage from 'hooks/Meteorology/Weather/Area/Status/useAreaStatusImage';
import useStatusImageDownload from 'hooks/Meteorology/Weather/Area/Status/useStatusImageDownload';
import EnlargeableImage from 'components/Meteorology/Fields/SynopticCharts/SynopticChart/Map/EnlargeableImage';
import CentralizedSpinner from 'components/CentralizedSpinner';

export default function CurrentPanel({ area }: { area: string }) {
  const { overviewOptions: periods, isLoading: periodsLoading } = useOverviewOptions();
  const {
    statusImageBase64,
    statusImageLoading,
    setImage,
    triggerStatus,
  } = useAreaStatusImage();
  const {
    period,
    onPeriodChange,
  } = useStatusPeriodSelection({
    area,
    setImage,
    triggerStatus,
  });
  const {
    onStatusDownloadClick,
    downloadDisabled,
  } = useStatusImageDownload(statusImageBase64, statusImageLoading, area);

  return (
    <Card className="shadow-sm forecast-instruction-card h-100">
      <Card.Header>
        OBSERVADO
      </Card.Header>
      <Card.Body>
        {periodsLoading ? <CentralizedSpinner /> : (
          <>
            <div className="d-lg-flex mt-3 mx-3 align-items-center">
              <FormSelect
                id="statusPeriodSelect"
                value={period}
                onChange={onPeriodChange}
              >
                <option value="">Período</option>
                {(periods || []).map(({ value, label }) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </FormSelect>
              <Button
                id="currentImageDownloadButton"
                size="sm"
                className="icon-button weather-download-button ms-lg-3"
                onClick={onStatusDownloadClick}
                disabled={downloadDisabled}
              >
                <FontAwesomeIcon icon={faDownload as IconProp} />
              </Button>
            </div>
            {statusImageLoading && <CentralizedSpinner />}
            {!statusImageLoading && statusImageBase64 && (
              <EnlargeableImage src={`data:image/png;base64,${statusImageBase64}`} className="mt-3" />
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
}

import { useEffect } from 'react';
import { ResponseError } from 'services/api/base';
import { useLazyGetInstructionPdfQuery } from 'services/api/products/flux';
import { useHandleRequestErrors } from 'utils/hooks';

export default function useInstructionPdf(id: number, triggerRequest: boolean) {
  const [trigger, { data, error, isFetching }] = useLazyGetInstructionPdfQuery();

  useEffect(() => {
    if (triggerRequest && data === undefined) {
      trigger({ id });
    }
  }, [triggerRequest, trigger, id, data]);

  useHandleRequestErrors(error as ResponseError);

  return {
    base64Pdf: data,
    loading: isFetching,
  };
}

import React from 'react';
import { dateRange } from 'helper/Meteorology/Weather/Area/History/';

export default function DataRangeDisplay({
  firstDate,
  lastDate,
}: {
  firstDate: string,
  lastDate: string,
}) {
  const { first, last } = dateRange(firstDate, lastDate);
  return (
    <p>
      {'De '}
      <span className="text-primary">{first}</span>
      {' Para '}
      <span className="text-primary">{last}</span>
    </p>
  );
}

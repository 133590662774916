import { GenericResponse } from 'services/api/base';
import { MltOptions } from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { FluxAutomaticLayoutTabs } from 'redux/reducers/fluxAutomaticLayoutReducer';
import { getUnixTime, isSameDay, parse } from 'date-fns';

export const NA_LABEL = 'N/A';
export const ACOMPH_DATE_LABEL_REGEX = /ACOMPH(\d{8})/;

export const automaticOptionMap = {
  subsistemas: 'Subsistema',
  reservatorios: 'Reservatório de Energia',
  bacias: 'Bacias Hidrográficas',
  postos: 'Posto Hidroenergético',
};

export type SpatialSubdivision = keyof typeof automaticOptionMap;
export type SpatialSubdivisionParamKey = 'subsistema' | 'reservatorio' | 'bacia' | 'posto';
export type SpatialSubdivisionParam = {
  [key in SpatialSubdivisionParamKey]?: string
};

export const spatialSubdivisionToParamKey: Record<
SpatialSubdivision,
SpatialSubdivisionParamKey
> = {
  subsistemas: 'subsistema',
  reservatorios: 'reservatorio',
  bacias: 'bacia',
  postos: 'posto',
};

export interface RawSpacialSubdivisionOption {
  [key: string]: number,
}

export interface RawScenarioOption {
  [key: string]: {
    [key: number] : {
      cenario: string,
      selectable: boolean,
    }
  },
}

export interface Scenario {
  scenario: string,
  selectable: boolean,
}

export interface LabeledScenarios {
  label: string,
  scenarios: Scenario[]
}

export type RawSpatialSubdivisions = {
  [key in SpatialSubdivision]: RawSpacialSubdivisionOption[];
};

export interface RawAutomaticOptions extends RawSpatialSubdivisions {
  cenarios: RawScenarioOption[],
}

export type SpacialSubdivisionOption = { label: string, value: number };

export type SpatialSubdivisions = {
  [key in SpatialSubdivision]: SpacialSubdivisionOption[];
};

export type SpatialSubdivisionWithOption = {
  spatialSubdivision: SpatialSubdivision,
  spacialSubdivisionOption: SpacialSubdivisionOption,
};

export interface AutomaticOptions extends SpatialSubdivisions {
  cenarios: LabeledScenarios[];
}

export interface GetDailyDataParams {
  data_acomph: string, // eg "latest"
  data_previsao: string, // eg "latest"
  versao: string, // eg "latest"
  lista_cenarios: string[],
  spatialSubdivisionParam: SpatialSubdivisionParam,
  incluir_acomph: boolean,
  incluir_mlt: boolean,
  pastSimulations: number[],
}

export interface GetMonthlyDataParams extends GetDailyDataParams {
  unidade: MltOptions,
}

export interface GetRevisionDataParams extends GetMonthlyDataParams {
  data_acomph: string,
  data_previsao: string,
  versao: string,
  ndiasprev: number,
  lista_cenarios: string[],
  revision: string,
  unidade: 'mwmed' | '%mlt',
  pastSimulations: number[],
}

export type GetRevisionScenarioParams = Omit<GetRevisionDataParams, 'pastSimulations' | 'incluir_mlt' | 'incluir_acomph'>;

export interface DailyData {
  [key: string]: (string | number)[][],
}

export interface MonthlyData {
  colLabels: string[],
  rowLabels: string[],
  values: number[][],
}
export type RevisionScenarios = MonthlyData;

export interface AutomaticRevisionData {
  colLabels: string[],
  rowLabels: string[],
  values: [number, string][][]
}

export interface RevisionDataPerScenario {
  [model: string]: {
    bacias: RevisionScenarios
    postos: RevisionScenarios
    sistema: RevisionScenarios
    subsistemas: RevisionScenarios
    reservatorios: RevisionScenarios
  }
}
export type RawRevisionDataPerScenario = GenericResponse<RevisionDataPerScenario>;

export type GetDailyDataResponse = GenericResponse<DailyData>;

type ModelList = string[];
type ForecastDates = {
  [date: string]: ModelList;
};
export type ClassicOptions = {
  [acomphDate: string]: ForecastDates;
};
export type RawGetClassicOptionsResponse = GenericResponse<ClassicOptions>;

export interface GetClassicRevisionsParams {
  acomphDateLabel: string;
  forecastDateLabel: string;
  model: string;
}
export type ClassicRevisionsData = string[];
export type RawGetClassicRevisionsResponse = GenericResponse<ClassicRevisionsData>;

export type GetClassicDataParams = GetClassicRevisionsParams & {
  revision: string;
};
export interface ClassicDataSubsystem {
  colLabels: string[];
  rowLabels: string[];
  values: [number, string][][];
}
export interface ClassicData {
  bacias: ClassicDataSubsystem;
  postos: ClassicDataSubsystem;
  sistema: ClassicDataSubsystem;
  subsistemas: ClassicDataSubsystem;
  reservatorios: ClassicDataSubsystem;
}
export interface RawClassicData {
  [model: string]: ClassicData;
}
export type RawGetClassicDataResponse = GenericResponse<RawClassicData>;

export interface RawPastSimulationResponse {
  id: number,
  name: string,
}

export interface ListPastSimulationsParams {
  user_id: number,
}

export type PastSimulationResponse = GenericResponse<RawPastSimulationResponse[]>;

export interface PastSimulation {
  id: number,
  name: string,
}

export type RelativeDate = 'latest' | `d-${number}`;

export interface SavePastSimulationParams {
  id?: number,
  acomphDate: RelativeDate,
  forecastDate: RelativeDate,
  version: string,
  model: string,
}

const transformGenericOptions = (
  options: RawSpacialSubdivisionOption[],
): SpacialSubdivisionOption[] => (
  options.map((o) => ({
    label: Object.keys(o)[0],
    value: Object.values(o)[0],
  }))
);

const transformScenarioOptions = (rawOptions: RawScenarioOption[]):
LabeledScenarios[] => rawOptions.map((rawOption: RawScenarioOption) => {
  const label = Object.keys(rawOption)[0];
  const scenarioOptions = rawOption[label];
  const scenarios = Object.values(scenarioOptions).map((scenarioOption) => ({
    scenario: scenarioOption.cenario,
    selectable: scenarioOption.selectable,
  }));
  const labeledScenarios: LabeledScenarios = {
    label,
    scenarios,
  };
  return labeledScenarios;
});

export const transformAutomaticOptions = (data: RawAutomaticOptions): AutomaticOptions => ({
  subsistemas: transformGenericOptions(data.subsistemas || []),
  reservatorios: transformGenericOptions(data.reservatorios || []),
  bacias: transformGenericOptions(data.bacias || []),
  postos: transformGenericOptions(data.postos || []),
  cenarios: transformScenarioOptions(data.cenarios || []),
});

export const transformDailyDataResponse = (response: GetDailyDataResponse) => (
  response.data
);

export const transformClassicOptionsResponse = (response: RawGetClassicOptionsResponse) => (
  response.data
);

export const transformClassicRevisionsResponse = (response: RawGetClassicRevisionsResponse) => (
  response.data
);

export const transformClassicDataResponse = (response: RawGetClassicDataResponse) => (
  Object.values(response.data)[0]
);

export const fluxZipFile = (layoutTab: FluxAutomaticLayoutTabs) => (
  `flux-automatic-${layoutTab}-${getUnixTime(new Date())}.zip`
);

export const transformListPastSimulationResponse = (
  response: PastSimulationResponse,
): PastSimulation[] => (
  response.data.map((rawPastSimulation) => ({
    id: rawPastSimulation.id,
    name: rawPastSimulation.name,
  }))
);

export const buildSubdivisionParam = (subdivision?: SpatialSubdivisionWithOption) => {
  const subdivisionParam: SpatialSubdivisionParam = {};

  if (subdivision) {
    const key = spatialSubdivisionToParamKey[subdivision.spatialSubdivision];
    const value = subdivision.spacialSubdivisionOption.label;
    subdivisionParam[key] = value;
  }

  return subdivisionParam;
};

export const convertAcomphDateLabel = (acomphLabel: string): Date | null => {
  const match = acomphLabel.match(ACOMPH_DATE_LABEL_REGEX);

  if (match) {
    return parse(match[1], 'yyyyMMdd', 0);
  }
  return null;
};

export const findAcomphDateLabel = (date: Date, labels: string[]): string => (
  labels.find((label) => isSameDay(date, convertAcomphDateLabel(label) as Date)) || ''
);

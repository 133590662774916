import { GetModelOptionsResponse } from 'types/Meteorology/Weather/Area/Status/getModelOptionsResponse';
import { GetOptionPeriodsResponse, StatusPeriods } from 'types/Meteorology/Weather/Area/Status/getOptionPeriodsResponse';
import { GetPlumaResponse, IndicesPrediction } from 'types/Meteorology/Weather/Area/Status/getPlumaResponse';
import { GetPredictionResponse, PredictionImage } from 'types/Meteorology/Weather/Area/Status/getPredictionResponse';
import { GetStatusResponse, StatusImage } from 'types/Meteorology/Weather/Area/Status/getStatusResponse';

export const transformGetModelOptionsResponse = (
  response: GetModelOptionsResponse,
): string[] => response.data;

export const transformGetOptionPeriodsResponse = (
  response: GetOptionPeriodsResponse,
): StatusPeriods => [
  ...response.data.monthly.map((period) => period.replace(' + ', '')),
  ...response.data.trimestral,
];

export const transformGetStatusResponse = (
  response: GetStatusResponse,
): StatusImage => (
  { statusImageBase64: response.data.status }
);

export const transformGetPredictionResponse = (
  response: GetPredictionResponse,
): PredictionImage => (
  { predictionImageBase64: response.data.prediction }
);

export const transformGetPlumaResponse = (
  response: GetPlumaResponse,
): IndicesPrediction => (
  response.data ? Object.entries(response.data).map(([model, indices]) => ({
    model,
    indices: Object.entries(indices).map(([date, value]) => ({
      date,
      value,
    })),
  })) : []
);

import React, { useState } from 'react';
import {
  Col, Row, Card, FormSelect, Button,
} from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTropicoOptions from 'hooks/Meteorology/Weather/Area/Status/Tropico/useTropicoOptions';
import useTropicoStatus from 'hooks/Meteorology/Weather/Area/Status/Tropico/useTropicoStatus';
import useOlrImageDownload from 'hooks/Meteorology/Weather/Area/Status/Tropico/useOlrImageDownload';
import useVpotImageDownload from 'hooks/Meteorology/Weather/Area/Status/Tropico/useVpotImageDownload';
import CentralizedSpinner from 'components/CentralizedSpinner';
import EnlargeableImage from 'components/Meteorology/Fields/SynopticCharts/SynopticChart/Map/EnlargeableImage';

export default function Tropico() {
  const { data, isLoading: optionsLoading } = useTropicoOptions();
  const [model, setModel] = useState('');
  const [wave, setWave] = useState('');
  const { olrImageBase64, vpotImageBase64, imagesLoading } = useTropicoStatus(model, wave);
  const {
    onOlrDownloadClick,
    olrDownloadDisabled,
  } = useOlrImageDownload(
    olrImageBase64,
    imagesLoading,
    model,
    wave,
  );
  const {
    onVpotDownloadClick,
    vpotDownloadDisabled,
  } = useVpotImageDownload(
    vpotImageBase64,
    imagesLoading,
    model,
    wave,
  );

  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setModel(value);
  };

  const handleWaveChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setWave(value);
  };

  return (
    <>
      <Row className="flex-fill p-5 h-100">
        <Col>
          <FormSelect
            id="periodSelect"
            value={wave}
            onChange={handleWaveChange}
          >
            <option value="">Ondas</option>
            {data?.waves.map((option) => (
              <option value={option} key={option}>{option}</option>
            ))}
          </FormSelect>
        </Col>
        <Col>
          <FormSelect
            id="modelSelect"
            value={model}
            onChange={handleModelChange}
          >
            <option value="">Modelo</option>
            {data?.models.map((option) => (
              <option value={option} key={option}>{option}</option>
            ))}
          </FormSelect>
        </Col>
      </Row>
      <Row className="flex-fill px-5 h-100">
        <Col>
          <Card className="shadow-sm forecast-instruction-card h-100">
            <Card.Header className="d-flex">
              <div className="flex-fill">
                ANOMALIA OLR
              </div>
              <div className="h-100 d-flex align-items-center">
                <Button
                  id="olrImageDownloadButton"
                  size="sm"
                  className="icon-button weather-download-button ms-lg-3"
                  onClick={onOlrDownloadClick}
                  disabled={olrDownloadDisabled}
                >
                  <FontAwesomeIcon icon={faDownload as IconProp} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              {optionsLoading ? (
                <CentralizedSpinner />
              ) : (
                <>
                  {imagesLoading && <CentralizedSpinner />}
                  <EnlargeableImage src={`data:image/png;base64,${olrImageBase64}`} className="mt-3" />
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col xl={6} className="mt-3 mt-xl-0">
          <Card className="shadow-sm forecast-instruction-card h-100">
            <Card.Header className="d-flex">
              <div className="flex-fill">
                ANOMALIA VPOT
              </div>
              <div className="h-100 d-flex align-items-center">
                <Button
                  id="vpotImageDownloadButton"
                  size="sm"
                  className="icon-button weather-download-button ms-lg-3"
                  onClick={onVpotDownloadClick}
                  disabled={vpotDownloadDisabled}
                >
                  <FontAwesomeIcon icon={faDownload as IconProp} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              {optionsLoading ? (
                <CentralizedSpinner />
              ) : (
                <>
                  {imagesLoading && <CentralizedSpinner />}
                  <EnlargeableImage src={`data:image/png;base64,${vpotImageBase64}`} className="mt-3" />
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
